import React, { useEffect, useMemo, useRef, useState } from "react";
import "./index.scss";
import { Button, Card, Divider, Form, Skeleton } from "antd";
import Meta from "antd/es/card/Meta";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import editUser from "../../../Assets/Icons/user-pen-alt.svg";
import leftArrow from "../../../Assets/Icons/chevron-left.svg";
import CustomForm from "../../../Components/Form";
import { postJobFields } from "../../../Utils/FormikFieldSkeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  addJob,
  getAllExperienceLevels,
  getAllIndustryTypes,
  getAllJobFunctions,
  searchEmployers,
  setJobDetails,
  updateJob,
} from "../../../Redux/Slices/JobSlice";
import { debounce } from "lodash";
import { getAllEmployers } from "../../../Redux/Slices/EmployerSlice";

const FormBtn = ({ resetForm, updateLoading, isEditDialog }) => {
  return (
    <div className="d-flex">
      <Button
        type="primary"
        htmlType="submit"
        className="fullWidth reg-height-btn"
        disabled={updateLoading}
        size="large"
      >
        {isEditDialog ? "Save Changes" : "Continue"}
      </Button>
    </div>
  );
};

const AddJob = () => {
  const {
    jobDetails,
    jobFunctionLoading,
    experienceLoading,
    industryLoading,
    updateLoading,
    searchLoading,
    loading,
  } = useSelector((state) => state.JobSlice);

  const employerSlice = useSelector((state) => state.EmployerSlice);
  const { jobDrawer } = useSelector((state) => state.DialogSlice);
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [jobFunctionArray, setJobFunctionArray] = useState([]);
  const [industryName, setIndustryName] = useState([]);
  const [experienceName, setExperienceName] = useState([]);
  const [jobFunctionName, setJobFunctionName] = useState([]);
  const [employerName, setEmployerName] = useState([]);
  const [searchValue, setSearchValue] = useState(jobDetails?.employerId);
  // const [searchValue, setSearchValue] = useState(
  //   jobDetails?.employerId?.value
  //     ? jobDetails?.employerId?.value
  //     : jobDetails?.employerId
  // );
  const isEdit = jobDrawer?.selectedJobDetails?.id;
  const [locationSearchText, setLocationSearchText] = useState("");

  const handleLocation = (e) => {
    if (!locations.includes(e)) {
      setLocations([...locations, e]);
    }
  };
  const handleJobFunctions = (e, { value, label }) => {
    const existingItem = jobFunctionArray.findIndex(
      (item) => item.value === value
    );
    if (existingItem === -1) {
      setJobFunctionArray([...jobFunctionArray, { value, label }]);
    }
  };
  const fieldLoading = () =>
    jobFunctionLoading || experienceLoading || industryLoading;
  const { jobLocations, jobFunctionIds } = jobDetails;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const element = document.getElementById("root");
  const fetchRef = useRef(0);
  const elementOffset = element?.getBoundingClientRect().top + window.scrollY;

  const removeLocation = (index) => {
    const temp = [...locations];
    temp.splice(index, 1);
    setLocations(temp);
  };
  const removeJobFunction = (index) => {
    const temp = [...jobFunctionArray];
    temp.splice(index, 1);
    setJobFunctionArray(temp);
  };
  const onFinish = (values, e) => {
    if (locations.length === 0) {
      toast.error("Please add locations");
      form.setFields([
        {
          name: "jobLocations",
          errors: ["Please use job locations"],
        },
      ]);
    } else if (jobFunctionArray.length === 0) {
      toast.error("Please add job functions");
      form.setFields([
        {
          name: "jobLocations",
          errors: ["Please add job functions"],
        },
      ]);
    } else if (values.jobDescription === "<p><br></p>") {
      toast.error("Please enter description");
    } else {
      dispatch(
        setJobDetails({
          values: {
            ...values,
            employerId: searchValue,
            jobLocations: locations,
            jobFunctionIds: jobFunctionArray,
          },
        })
      );
      navigate("/dashboard/jobs/preview-job");
    }
  };
  const industryNameHandler = (data) => {
    const temp = [];
    data.forEach(({ id, name }) => temp.push({ value: id, label: name }));
    setIndustryName(temp);
  };
  const experienceNameHandler = (data) => {
    const temp = [];
    data.forEach(({ id, name }) => temp.push({ value: id, label: name }));
    setExperienceName(temp);
  };
  const jobFunctionNameHandler = (data) => {
    const temp = [];
    data.forEach(({ id, name }) => temp.push({ value: id, label: name }));
    setJobFunctionName(temp);
  };
  const employerNameHandler = (data) => {
    const temp = [];
    data.forEach(({ id, user, companyName }) =>
      temp.push({
        value: id,
        label: `${user?.name} (${companyName})`,
        title: companyName,
      })
    );
    setEmployerName(temp);
  };

  useEffect(() => {
    dispatch(getAllIndustryTypes({ industryNameHandler }));
    dispatch(getAllExperienceLevels({ experienceNameHandler }));
    dispatch(getAllJobFunctions({ jobFunctionNameHandler }));
    dispatch(
      getAllEmployers({
        navigate,
        page: 1,
        employerNameHandler,
        limit: 1000000000,
      })
    );

    jobLocations?.length > 0 && setLocations(jobLocations);
    jobFunctionIds?.length > 0 && setJobFunctionArray(jobFunctionIds);
  }, []);
  const customConfig = [
    { name: "industryTypeId", options: industryName },
    {
      name: "allowedExperienceLevels",
      options: experienceName,
    },
    {
      name: "jobFunctionIds",
      options: jobFunctionName,
    },
    {
      name: "employerId",
      options: employerSlice?.loading ? [] : employerName,
    },
  ];
  useEffect(() => {
    window.scrollTo({
      top: elementOffset - 80,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  const btnProps = {
    isEdit,
    updateLoading,
  };
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      console.log("value", value);
      if (value === "") {
        dispatch(getAllEmployers({ employerNameHandler }));
      } else dispatch(searchEmployers({ employerNameHandler, search: value }));

      // });
    };
    return debounce(loadOptions, 800);
  }, [800]);
  return (
    <div className={!isEdit && "add-job-container"}>
      <Card
        className="add-job-card"
        // title={

        // }
      >
        <Meta
          avatar={<img src={editUser} width="40px" alt="edit-icon" />}
          title="Post a New Job"
        />

        <div
          style={{
            height: 1,
            backgroundColor: "#e1e1e1",
            width: "100%",
            marginBottom: 16,
          }}
        />
        {fieldLoading() ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <CustomForm
            fields={postJobFields}
            onFinish={onFinish}
            FormBtn={FormBtn}
            initialValues={jobDetails}
            handleLocation={handleLocation}
            handleJobFunctions={handleJobFunctions}
            locations={locations}
            removeLocation={removeLocation}
            jobFunctionArray={jobFunctionArray}
            customConfig={customConfig}
            btnProps={btnProps}
            isEditPopup={isEdit}
            locationSearchText={locationSearchText}
            setLocationSearchText={setLocationSearchText}
            searchValue={searchValue}
            removeJobFunction={removeJobFunction}
            debounceFetcher={debounceFetcher}
            fetching={searchLoading || loading}
            setSearchValue={setSearchValue}
          />
        )}
      </Card>
      <ToastContainer />
    </div>
  );
};

export default AddJob;
