import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiStatics } from "../../Utils/constants";
import { expireTokenHandler } from "../../Utils/helpers";

const initialState = {
  selectedJob: {},

  // employers: [],
  loading: false,
  downloading: false,
  uploadLoading: false,
  searchLoading: false,
  getJobsLoading: false,
  updateLoading: false,
  customExperience: 1,
  jobs: [],
  jobDetails: {},
  error: {},
};

export const getAllJobs = createAsyncThunk(
  `Job/getAllJobs`,
  async ({ status, page, limit }) => {
    try {
      console.log("getAllJobs");
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}?sort=-createdAt&status=${status}&page=${page}&limit=${limit}`,
        config
      );

      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
// export const getAllEmployers = createAsyncThunk(
//   `Job/getAllEmployers`,
//   async ({ employerNameHandler }) => {
//     try {
//       console.log("getAllEmployers");
//       const token = localStorage.getItem("token");
//       const config = {
//         headers: {
//           Authorization: "Bearer " + token,
//           // Accept: "*/*",
//         },
//       };
//       let response = await axios.get(
//         `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.dashboard.dashboard}/${ApiStatics.userTypes.employers}?sort=-createdAt&page=1&limit=10`,
//         config
//       );

//       employerNameHandler && employerNameHandler(response.data.data.data);
//       return {
//         success: true,
//         total: response.data.total,
//       };
//     } catch (error) {
//       console.log("error", error);
//       return {
//         success: false,
//         error: error.message,
//       };
//     }
//   }
// );
export const searchEmployers = createAsyncThunk(
  `Job/searchEmployers`,
  async ({ employerNameHandler, search, limit }) => {
    try {
      console.log("getAllEmployers");
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.dashboard.dashboard}/${ApiStatics.userTypes.employers}/search?search=${search}`,
        config
      );

      employerNameHandler && employerNameHandler(response.data.data.data);
      return {
        success: true,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const getJobById = createAsyncThunk(`Job/getJobById`, async ({ id }) => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    let response = await axios.get(
      `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${id}`,
      config
    );

    return {
      success: true,
      data: response.data.data.data,
      total: response.data.total,
    };
  } catch (error) {
    console.log("error", error);
    return {
      success: false,
      error: error.message,
    };
  }
});
export const downloadJobs = createAsyncThunk(`Job/downloadJobs`, async () => {
  try {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    let response = await axios.get(
      `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${ApiStatics.jobs.export}`,
      config
    );
    console.log("response", response.data);
    window.open(response.data.data.url, "_blank");
    return {
      success: true,
    };
  } catch (error) {
    console.log("error", error);
    return {
      success: false,
      error: error.message,
    };
  }
});
export const uploadJobs = createAsyncThunk(
  `Job/uploadJobs`,
  async ({ file, navigate, toast }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      const formData = new FormData();
      formData.append("file", file);
      await axios.put(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${ApiStatics.jobs.import}`,
        formData,
        config
      );
      toast.success("File uploaded successfully");
      setTimeout(() => {
        navigate(0);
      }, 1500);

      return {
        success: true,
      };
    } catch (error) {
      toast.error(error.response.data.message);
      setTimeout(() => {
        navigate(0);
      }, 1500);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const createJob = createAsyncThunk(
  `jobs/createJob`,
  async ({ values, toast, navigate }) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    try {
      const customFunctionIds = values.jobFunctionIds.map((obj) => obj.value);
      let response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}`,
        { ...values, jobFunctionIds: customFunctionIds },
        config
      );
      console.log("response.data.data.data", response.data.data.data);
      const id = response.data.data.data.id;
      await axios.patch(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${id}`,
        { status: "approved" },
        config
      );
      toast.success("Job created successfully");
      setTimeout(() => {
        navigate("/dashboard/jobs/1");
      }, 1000);
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      const status = error.response.status;
      expireTokenHandler(status);
      return {
        success: false,
        // error: error.message,
      };
    }
  }
);
export const updateJob = createAsyncThunk(
  `jobs/updateJob`,
  async ({
    values,
    toast,
    id,
    handleChecked,
    e,
    closeDrawer,
    handleCancel,
    navigate,
  }) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    try {
      const customFunctionIds = values?.jobFunctionIds?.map((obj) => obj.value);

      let response = customFunctionIds
        ? await axios.patch(
            `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${id}`,
            { ...values, jobFunctionIds: customFunctionIds },
            config
          )
        : await axios.patch(
            `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${id}`,
            values,
            config
          );
      console.log("response.data.data.data", handleChecked, e);
      toast.success("Job updated successfully");
      handleChecked && handleChecked(e);
      setTimeout(() => {
        handleCancel && handleCancel();
        closeDrawer && closeDrawer();
        navigate && navigate(0);
      }, 1000);
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      const status = error.response.status;
      expireTokenHandler(status);
      return {
        success: false,
        // error: error.message,
      };
    }
  }
);
export const approveJob = createAsyncThunk(
  `jobs/approveJob`,
  async ({ toast, id }) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    try {
      let response = await axios.patch(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${id}`,
        { status: "approved" },
        config
      );
      toast.success("Job approved successfully");

      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      const status = error.response.status;
      expireTokenHandler(status);
      return {
        success: false,
        // error: error.message,
      };
    }
  }
);
export const rejectJob = createAsyncThunk(
  `jobs/rejectJob`,
  async ({ toast, id }) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    try {
      let response = await axios.patch(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${id}`,
        { status: "rejected" },
        config
      );
      toast.success("Job rejected successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      const status = error.response.status;
      expireTokenHandler(status);
      return {
        success: false,
        // error: error.message,
      };
    }
  }
);
export const getAllIndustryTypes = createAsyncThunk(
  `jobs/getAllIndustryTypes`,
  async ({ industryNameHandler, name, page, limit }) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${ApiStatics.jobs.industryTypes}`,
        config
      );

      console.log("cat fetxh", response.data.data.data);
      industryNameHandler && industryNameHandler(response.data.data.data);
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      //   toast.error(error.response.data.message);
      console.log("error", error);
      const status = error.response.status;
      expireTokenHandler(status);
      return {
        success: false,
        // error: error.message,
      };
    }
  }
);
export const getAllExperienceLevels = createAsyncThunk(
  `jobs/getAllExperienceLevels`,
  async ({ experienceNameHandler, name, page, limit }) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${ApiStatics.jobs.experience}`,
        config
      );

      console.log("cat fetxh", response.data.data.data);
      experienceNameHandler && experienceNameHandler(response.data.data.data);
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      //   toast.error(error.response.data.message);
      console.log("error", error);
      const status = error.response.status;
      expireTokenHandler(status);
      return {
        success: false,
        // error: error.message,
      };
    }
  }
);
export const getAllJobFunctions = createAsyncThunk(
  `jobs/getAllJobFunctions`,
  async ({ jobFunctionNameHandler, name, page, limit }) => {
    const token = localStorage.getItem("token");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        // Accept: "*/*",
      },
    };
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.jobs.jobs}/${ApiStatics.jobs.functions}/?randomOrder=true`,
        config
      );

      jobFunctionNameHandler && jobFunctionNameHandler(response.data.data.data);
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      //   toast.error(error.response.data.message);
      console.log("error", error);
      const status = error.response.status;
      expireTokenHandler(status);
      return {
        success: false,
        // error: error.message,
      };
    }
  }
);
const JobSlice = createSlice({
  name: "Job",
  initialState,
  reducers: {
    setJobDetails(state, { payload }) {
      state.jobDetails = { ...payload.values };
    },
    resetJobDetails(state) {
      state.jobDetails = {};
    },
    addJob(state, { payload }) {
      payload.job.id = state.jobs.length + 1;
      const temp = [...state.jobs, payload.job];
      state.jobs = temp;
    },
    setSelectedJob(state, { payload }) {
      state.selectedJob = { ...payload.job };
    },
    setCustomExperience(state, { payload }) {
      state.customExperience = { ...payload.experience };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllJobs.pending, (state, action) => {
        state.loading = true;
        state.jobs = [];
        state.error = initialState.error;
      })
      .addCase(getAllJobs.rejected, (state, { payload }) => {
        state.loading = true;
        state.error = payload.error;
      })
      .addCase(getAllJobs.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.jobs = payload.data;
        state.total = payload.total;
        state.success = true;
      })
      .addCase(getJobById.pending, (state, action) => {
        state.getJobsLoading = true;
        state.error = initialState.error;
      })
      .addCase(getJobById.rejected, (state, { payload }) => {
        state.getJobsLoading = true;
        state.error = payload.error;
      })
      .addCase(getJobById.fulfilled, (state, { payload }) => {
        state.getJobsLoading = false;
        state.jobDetails = payload.data;
        state.success = true;
      });
    builder
      .addCase(createJob.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.success = true;
        // state.jobDetails = {};
        state.editJobDialog = {
          open: false,
        };
      })
      .addCase(createJob.pending, (state, action) => {
        // state.jobsFetched = false;
        state.updateLoading = true;
        state.error = initialState.error;
      })
      .addCase(createJob.rejected, (state, action) => {
        state.updateLoading = false;
        state.error = action.payload;
      });
    // builder
    //   .addCase(getAllEmployers.fulfilled, (state, action) => {
    //     state.loading = false;
    //   })
    //   .addCase(getAllEmployers.pending, (state, action) => {
    //     // state.jobsFetched = false;
    //     state.loading = true;
    //     state.error = initialState.error;
    //   })
    //   .addCase(getAllEmployers.rejected, (state, action) => {
    //     state.loading = false;
    //     state.error = action.payload;
    //   });
    builder
      .addCase(searchEmployers.fulfilled, (state, action) => {
        state.searchLoading = false;
      })
      .addCase(searchEmployers.pending, (state, action) => {
        // state.jobsFetched = false;
        state.searchLoading = true;
        state.error = initialState.error;
      })
      .addCase(searchEmployers.rejected, (state, action) => {
        state.searchLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(uploadJobs.fulfilled, (state, action) => {
        state.uploadLoading = false;
      })
      .addCase(uploadJobs.pending, (state, action) => {
        // state.jobsFetched = false;
        state.uploadLoading = true;
        state.error = initialState.error;
      })
      .addCase(uploadJobs.rejected, (state, action) => {
        state.uploadLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(downloadJobs.fulfilled, (state, action) => {
        state.downloading = false;
      })
      .addCase(downloadJobs.pending, (state, action) => {
        // state.jobsFetched = false;
        state.downloading = true;
        state.error = initialState.error;
      })
      .addCase(downloadJobs.rejected, (state, action) => {
        state.downloading = false;
        state.error = action.payload;
      });
    builder
      .addCase(updateJob.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.success = true;
        state.editJobDialog = {
          open: false,
        };
      })
      .addCase(updateJob.pending, (state, action) => {
        // state.jobsFetched = false;
        state.updateLoading = true;
        state.error = initialState.error;
      })
      .addCase(updateJob.rejected, (state, action) => {
        state.updateLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(approveJob.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.success = true;
      })
      .addCase(approveJob.pending, (state, action) => {
        // state.jobsFetched = false;
        state.updateLoading = true;
        state.error = initialState.error;
      })
      .addCase(approveJob.rejected, (state, action) => {
        state.updateLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(rejectJob.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.success = true;
      })
      .addCase(rejectJob.pending, (state, action) => {
        // state.jobsFetched = false;
        state.updateLoading = true;
        state.error = initialState.error;
      })
      .addCase(rejectJob.rejected, (state, action) => {
        state.updateLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getAllIndustryTypes.fulfilled, (state, action) => {
        let { data, total } = action.payload;
        state.industryLoading = false;
        state.industryTypes = data;
        state.success = true;
        state.total = total;
      })
      .addCase(getAllIndustryTypes.pending, (state, action) => {
        state.industryLoading = true;
        state.error = initialState.error;
      })
      .addCase(getAllIndustryTypes.rejected, (state, action) => {
        console.log("rejected", action);
        state.industryLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getAllExperienceLevels.fulfilled, (state, action) => {
        let { data, total } = action.payload;
        state.experienceLoading = false;
        state.experiences = data;
        state.success = true;
        state.total = total;
      })
      .addCase(getAllExperienceLevels.pending, (state, action) => {
        state.experienceLoading = true;
        state.error = initialState.error;
      })
      .addCase(getAllExperienceLevels.rejected, (state, action) => {
        state.experienceLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getAllJobFunctions.fulfilled, (state, action) => {
        let { data, total } = action.payload;
        state.jobFunctionLoading = false;
        state.jobFunctions = data;
        state.success = true;
        state.total = total;
      })
      .addCase(getAllJobFunctions.pending, (state, action) => {
        state.jobFunctionLoading = true;
        state.error = initialState.error;
      })
      .addCase(getAllJobFunctions.rejected, (state, action) => {
        state.jobFunctionLoading = false;
        state.error = action.payload;
      });
  },
});

export default JobSlice.reducer;
export const {
  setJobDetails,
  setSelectedJob,
  setCustomExperience,
  addJob,
  resetJobDetails,
} = JobSlice.actions;
