import React from "react";
import { Radio, Table, Input, Button } from "antd";
import Title from "antd/es/typography/Title";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SearchOutlined } from "@ant-design/icons";
import upload from "../../Assets/Icons/arrow-up-from-bracket.svg";
import download from "../../Assets/Icons/download.svg";
import add from "../../Assets/Icons/plus-large.svg";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  downloadJobs,
  resetJobDetails,
  uploadJobs,
} from "../../Redux/Slices/JobSlice";

const CustomTable = ({
  title,
  dataSource,
  columns,
  loading,
  renderTabs,
  status,
  handleStatus,
  pagination,
  onChange,
  onSearch,
  isJob,
  renderTabsJobs,
}) => {
  const { downloading, uploadLoading } = useSelector((state) => state.JobSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddJob = () => {
    dispatch(resetJobDetails());
    navigate("/dashboard/jobs/add-job");
  };
  const handleDownload = () => dispatch(downloadJobs());
  const handleUpload = (e) => {
    console.log("inside puload");
    const file = e.target.files[0];
    if (!file.name.includes("xlsx")) {
      toast.error("Please upload excel file only ");
    } else dispatch(uploadJobs({ file, navigate, toast }));
    console.log("file", file);
  };
  return (
    <>
      <div className="table-header  ">
        <div className="table-title-container">
          <Title
            level={3}
            className="text-black internal-table-title font-medium "
          >
            {title}
          </Title>
          {renderTabsJobs && (
            <Radio.Group
              onChange={handleStatus}
              value={status}
              className="job-radio-group"
            >
              <Radio.Button value="approved">Approved</Radio.Button>
              <Radio.Button value="pending">Under Review</Radio.Button>
              <Radio.Button value="rejected">Rejected</Radio.Button>
            </Radio.Group>
          )}
        </div>

        <div className="table-header-right">
          {onSearch && (
            <div>
              <Input
                placeholder="Search here..."
                onChange={onSearch}
                prefix={<SearchOutlined />}
                style={{ height: "40px" }}
                className="search-input"

                // size="small"
              />
            </div>
          )}

          {renderTabs && (
            <Radio.Group
              onChange={handleStatus}
              value={status}
              // style={{
              //   marginBottom: 8,
              // }}
            >
              <Radio.Button value="approved">Approved</Radio.Button>
              <Radio.Button value="review">Under Review</Radio.Button>
            </Radio.Group>
          )}
          {isJob && (
            <div className="table-header-extra-actions">
              <Button
                icon={<img src={upload} alt="upload" />}
                size="large"
                className="d-flex align-center btn-14 job-table-action import-jobs-btn"
                disabled={uploadLoading}
              >
                <input
                  type="file"
                  className="upload-thumbnail-input"
                  onChange={handleUpload}
                />
                Import jobs
              </Button>
              <Button
                icon={<img src={download} alt="upload" />}
                size="large"
                className="d-flex align-center btn-14 job-table-action"
                disabled={downloading}
                onClick={handleDownload}
              >
                Download jobs
              </Button>

              <Button
                icon={<img src={add} alt="upload" />}
                size="large"
                type="primary"
                className="d-flex align-center btn-14 job-table-action"
                onClick={handleAddJob}
              >
                Create new job
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className="table-wrapper">
        <ToastContainer />
        <Table
          dataSource={dataSource}
          columns={columns}
          loading={loading}
          pagination={pagination && pagination}
          onChange={onChange}
        />
      </div>
    </>
  );
};

export default CustomTable;
