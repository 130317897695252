export const ApiStatics = {
  auth: {
    auth: "auth",
    signup: "sign-up",
    login: "sign-in",
  },
  profile: {
    profile: "profile",
    preferences: "preferences",
  },

  accept: {
    accept: "accept",
  },
  dashboard: {
    dashboard: "dashboard",
    stats: "stats",
  },
  userTypes: {
    employer: "employer",
    employers: "employers",
    candidate: "candidate",
  },
  jobs: {
    jobs: "jobs",
    business: "business",
    miniature: "miniature",
    industryTypes: "industry-types",
    experience: "experience-levels",
    functions: "job-functions",
    unmached: "unmatched",
    matched: "matched",
    apply: "apply",
    export: "export",
    import: "import",
  },
  employers: {
    employers: "employers",
  },
  candidates: {
    candidates: "candidates",
    candidate: "candidate",
  },
  requests: {
    requests: "requests",
    accept: "accept",
  },
};
