import { Col, Form, Row, Tag } from "antd";
import React, { useEffect, useMemo, useRef } from "react";
import { formFieldToRender } from "../../Utils/renderer";
import Paragraph from "antd/es/typography/Paragraph";

const CustomForm = ({
  fields,
  formName,
  jobFunctionArray,
  initialValues,
  onFinish,
  customConfig,
  setField,
  style,
  FormBtn,
  handleLocation,
  handleJobFunctions,
  locations,
  removeLocation,
  setExperienceLevel,
  btnProps,
  locationSearchText,
  setLocationSearchText,
  searchValue,
  debounceFetcher,
  fetching,
  removeJobFunction,
  setSearchValue,
}) => {
  const quillRef = useRef(null);
  const [form] = Form.useForm();

  const quillModules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],

          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          // ["link", "image"],
          // ["clean"],
        ],
        // handlers: {
        //   image: () => imageHandler(quillRef, dispatch, navigate, id),
        // },
      },
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );
  // useEffect(() => {}, [fields]);
  return (
    <Form
      name={formName}
      layout="vertical"
      initialValues={{ ...initialValues, remember: true }}
      onFinish={onFinish}
      style={{ ...style }}
      preserve={false}
    >
      <Row gutter={[12, 0]}>
        {fields?.map(
          ({
            name,
            label,
            size,
            rules,
            placeholder,
            type,
            options,
            isDisabled,
            multiline,
            helperText,
            boxSize,
          }) => (
            <Col
              xs={size.sm}
              sm={size.sm}
              md={size.md}
              xl={size.xl}
              xxl={size.xxl}
            >
              <Form.Item
                name={name}
                label={label}
                rules={rules}
                shouldUpdate
                style={helperText && { marginBottom: "8px" }}
              >
                {locations?.length > 0 && name === "jobLocations" && (
                  <div className="d-flex align-center flex-wrap">
                    {" "}
                    {locations?.map((location, index) => (
                      <Tag
                        key={location}
                        closable
                        className="location-tags"
                        onClose={() => removeLocation(index)}
                      >
                        {location}
                      </Tag>
                    ))}
                  </div>
                )}

                {jobFunctionArray?.length > 0 && name === "jobFunctionIds" && (
                  <div className="d-flex align-center flex-wrap">
                    {" "}
                    {jobFunctionArray?.map((jobFunction, index) => (
                      <Tag
                        key={jobFunction}
                        closable
                        className="location-tags"
                        onClose={() => removeJobFunction(index)}
                      >
                        {jobFunction.label}
                      </Tag>
                    ))}
                  </div>
                )}
                {formFieldToRender({
                  formName,
                  name,
                  type,
                  placeholder,
                  options,
                  multiline,
                  customConfig,
                  setField,
                  initialValues,
                  isDisabled,
                  boxSize,
                  quillRef,
                  quillModules,
                  handleLocation,
                  handleJobFunctions,
                  form,
                  locations,
                  setExperienceLevel,
                  locationSearchText,
                  setLocationSearchText,
                  searchValue,
                  debounceFetcher,
                  fetching,
                  setSearchValue,
                  size,
                })}
              </Form.Item>
              {helperText && (
                <Paragraph className="text-helper">{helperText}</Paragraph>
              )}
            </Col>
          )
        )}
      </Row>
      <FormBtn {...btnProps} />
    </Form>
  );
};

export default CustomForm;
