import { Divider } from "antd";
import Paragraph from "antd/es/typography/Paragraph";

const Header = ({ icon, heading }) => (
  <div className="custom-dialog-header">
    <div className="d-flex align-center">
      <img src={icon} width="40px" alt="lg" />
      <Paragraph className="profile-dialog-heading text-black">
        {heading}
      </Paragraph>
    </div>

    <Divider className="modal-divider" />
  </div>
);

export default Header;
