import React, { useEffect, useState } from "react";
import Title from "antd/es/typography/Title";
import { Row, Col, Skeleton } from "antd";
import _ from "lodash";
import rising from "../../Assets/Images/rising.svg";
import falling from "../../Assets/Images/falling.svg";

import "./index.scss";
import { candidatesList } from "../../Utils/tableData";
import CustomTable from "../../Components/Table";
import StatCard from "./StatCard";
import { useDispatch, useSelector } from "react-redux";
import { getDashStats } from "../../Redux/Slices/DashboardSlice";
import {
  getAllCandidateRequests,
  searchCandidateRequests,
} from "../../Redux/Slices/CandidateSlice";
import { useNavigate, useParams } from "react-router-dom";
import EditDialog from "../../Components/Dialog";
const Dashboard = () => {
  const { stats, dashboardLoading, updatedLoading } = useSelector(
    (state) => state.DashboardSlice
  );
  const navigate = useNavigate();
  const { candidateRequests, candidateLoading, totalRequests } = useSelector(
    (state) => state.CandidateSlice
  );
  const dispatch = useDispatch();
  // localStorage.setItem('localSorter', `createdAt`);
  const defaulLocalSort = localStorage.getItem("localSorter");
  const [limit, setLimit] = useState(10);
  const [sorter, setSorter] = useState(defaulLocalSort || "createdAt");
  const { pageNumber } = useParams();
  const dashboardStats = [
    {
      id: 1,
      name: "Total Jobs",
      value: stats?.totalJobs,
      increment: 20,
      decrement: 0,
      // img: rising,
    },
    {
      id: 2,
      name: "Total Employers",
      value: stats?.totalEmployers,
      increment: 0,
      decrement: 10,
      img: falling,
    },
    {
      id: 3,
      name: "Total Candidates",
      value: stats?.totalCandidates,
      increment: 20,
      decrement: 0,
      img: rising,
    },
    {
      id: 4,
      name: "Total Waitlist",
      value: stats?.totalCandidateRequests,
      increment: 20,
      decrement: 0,
      img: rising,
    },
  ];
  const pagination = {
    total: totalRequests,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: limit,
    current: pageNumber,
    onChange: (newPage) => {
      navigate(`/dashboard/${newPage}`);
    },
    onShowSizeChange: (e, value) => setLimit(value),
  };
  useEffect(() => {
    dispatch(
      getAllCandidateRequests({
        navigate,
        page: pageNumber,
        limit,
        sort: sorter,
      })
    );
  }, []);
  useEffect(() => {
    dispatch(
      getAllCandidateRequests({
        navigate,
        page: pageNumber,
        limit,
        sort: sorter,
      })
    );
    dispatch(getDashStats({ navigate }));
  }, [pageNumber, limit, sorter]);
  const handleTableChange = (pagination, filters, sorters) => {
    const sign = sorters.order === "descend" ? "-" : "";
    const fieldToset =
      sorters.field === "jobFunction" ? "jobFunctionId" : sorters.field;
    if (sorters.order) {
      setSorter(`${sign}${fieldToset}`);
      localStorage.setItem("localSorter", `${sign}${fieldToset}`);
    } else {
      localStorage.setItem("localSorter", `createdAt`);
      setSorter("createdAt");
    }
  };
  const onSearch = _.debounce(function (e, _e, info) {
    e.preventDefault();
    if (e.target.value === "") {
      dispatch(
        getAllCandidateRequests({
          navigate,
          page: pageNumber,
          limit,
          sort: sorter,
        })
      );
      return;
    } else {
      dispatch(
        searchCandidateRequests({
          navigate,
          page: pageNumber,
          limit,
          sort: sorter,
          text: e.target.value,
        })
      );
    }
  }, 1000);
  return (
    <div className="dashboard-container">
      <Title level={3} className="text-black reset-margin font-medium ">
        Dashboard
      </Title>
      <Row className="stats-container" gutter={[16, 16]}>
        {dashboardLoading ? (
          <Skeleton active />
        ) : (
          dashboardStats.map((stat) => (
            <Col md={4.8}>{<StatCard {...stat} />}</Col>
          ))
        )}
      </Row>
      <Row gutter={[24, 24]} className="dashboard-tables-container">
        <Col md={24}>
          <CustomTable
            title="New Candidates Approval List"
            dataSource={candidateRequests}
            columns={candidatesList}
            loading={candidateLoading}
            pagination={pagination}
            onChange={handleTableChange}
            onSearch={onSearch}
          />
        </Col>
      </Row>
      <EditDialog loading={updatedLoading} />
    </div>
  );
};

export default Dashboard;
