import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiStatics } from "../../Utils/constants";
import axios from "axios";
import { expireTokenHandler } from "../../Utils/helpers";

const initialState = {
  employers: [],
  total: 0,
  loading: false,
  updateLoading: false,
};

export const getAllEmployers = createAsyncThunk(
  `Employer/getAllEmployers`,
  async ({ navigate, page, employerNameHandler, limit }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.dashboard.dashboard}/${ApiStatics.employers.employers}?page=${page}&limit=${limit}&sort=-createdAt`,
        config
      );

      employerNameHandler && employerNameHandler(response.data.data.data);

      //   navigate("/dashboard");
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      //   toast.error(error.response.data.message);
      expireTokenHandler(error.response.data.message, navigate);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

const EmployerSlice = createSlice({
  name: "Employer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEmployers.pending, (state, action) => {
        state.loading = true;
        state.error = initialState.error;
      })
      .addCase(getAllEmployers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.employers = payload.data;
        state.total = payload.total;
        state.success = true;
      });
  },
});

export default EmployerSlice.reducer;
export const {} = EmployerSlice.actions;
