import React, { useEffect, useState } from "react";
import "./index.scss";
import CustomTable from "../../Components/Table";
import { employersList } from "../../Utils/tableData";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployers } from "../../Redux/Slices/EmployerSlice";
import { useNavigate } from "react-router-dom";

const Employers = () => {
  const { employers, loading, total } = useSelector(
    (state) => state.EmployerSlice
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const pagination = {
    total,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSize: limit,
    onChange: (newPage) => setPage(newPage),
    onShowSizeChange: (e, value) => setLimit(value),
  };
  useEffect(() => {
    dispatch(getAllEmployers({ navigate, page, limit }));
  }, [page, limit]);

  return (
    <div className="employers-container">
      <CustomTable
        title="New Employers List"
        dataSource={employers}
        columns={employersList}
        loading={loading}
        pagination={pagination}
      />
    </div>
  );
};

export default Employers;
