import { Col, Divider, Row, Tag } from "antd";
import Typography from "antd/es/typography/Typography";

const ProfileSubSection = ({ label, value, isChips }) => (
  <div className="profile-sub-section">
    <Typography className="profile-field-heading">{label}</Typography>
    {isChips ? (
      <Row>
        {Array.isArray(value) && value?.length > 0 ? (
          value?.map((value) => (
            <Col md={2.5} style={{ marginTop: "20px" }}>
              <Tag className="location-tags">{value}</Tag>
            </Col>
          ))
        ) : (
          <Typography className="profile-field-value text-black">
            No Data
          </Typography>
        )}
      </Row>
    ) : (
      <Typography className="profile-field-value text-black">
        {value}
      </Typography>
    )}
  </div>
);

export default ProfileSubSection;
