import * as React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "../Layouts/Dashboard";
import LoginLayout from "../Layouts/Login";
import LoginSignUp from "../Containers/Auth/Login-SignUp";
import ForgotPassword from "../Containers/Auth/ForgotPassword";
import VerifyAccount from "../Containers/Auth/VerifyAccount";
import VerificationCode from "../Containers/Auth/VerificationCode";
import Candidates from "../Containers/Candidates";
import Employers from "../Containers/Employers";
import Dashboard from "../Containers/Dashboard";
import Jobs from "../Containers/Jobs";
import AddJob from "../Containers/Jobs/AddJob";
import PreviewJob from "../Containers/Jobs/PreviewJob";

function Router() {
  const isLoggedIn = localStorage.getItem("token");
  let element = useRoutes([
    {
      path: "/",
      // element: <Login />,
      element: !isLoggedIn ? <LoginLayout /> : <Navigate to="/dashboard" />,
      children: [
        // { path: "", element: <LoginSignUp /> },
        { path: "", element: <LoginSignUp /> },
        { path: "/signup", element: <LoginSignUp /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "reset-password", element: <ForgotPassword /> },
        { path: "verify-account", element: <VerifyAccount /> },
        { path: "verify-code", element: <VerificationCode /> },
      ],
    },
    {
      path: "/dashboard",
      element: <Navigate to="/dashboard/1" />,
    },
    {
      path: "/dashboard",
      // element: <Login />,
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        { path: ":pageNumber", element: <Dashboard /> },
        { path: "candidates/:pageNumber", element: <Candidates /> },
        { path: "employers", element: <Employers /> },
        { path: "jobs/:pageNumber", element: <Jobs /> },
        { path: "jobs/add-job", element: <AddJob /> },
        { path: "jobs/preview-job", element: <PreviewJob /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/dashboard/1" />,
    },
  ]);

  return element;
}

export default Router;
