import React from "react";
import "./index.scss";
import logoText from "../../../Assets/Icons/logo_text.svg";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { useLocation, useNavigate } from "react-router-dom";
import CustomForm from "../../../Components/Form";
import BackContinue from "../../../Components/Form/BackContinue";
import { verificationCodeFields } from "../../../Utils/FormikFieldSkeleton";

const VerificationCode = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const onFinish = (values) => navigate("/dashboard/add-job");
  return (
    <div className="verification-container">
      <img src={logoText} width="201px" alt="logo" />
      <Title level={1} className="font-medium text-black ">
        Enter Verification Code
      </Title>
      <Paragraph>
        We’ve sent you a verification code to your email address
        <br />
        <span className="text-dark-primary"> {state}</span>
      </Paragraph>
      <CustomForm
        fields={verificationCodeFields}
        onFinish={onFinish}
        style={{ marginTop: "40px" }}
        FormBtn={BackContinue}
      />
    </div>
  );
};

export default VerificationCode;
