import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { ApiStatics } from "../../Utils/constants";
import { expireTokenHandler } from "../../Utils/helpers";

const initialState = {
  openCards: [],
  selectedCandidateTab: "matched",
  candidateRequests: [],
  total: 0,
  totalRequests: 0,
  currentCandidateData: {},
  candidates: [],
  candidateLoading: false,
  updateLoading: false,
};

export const getAllCandidates = createAsyncThunk(
  `Candidate/getAllCandidates`,
  async ({ verified, page, limit }) => {
    try {
      console.log("getAllCandidates");
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.dashboard.dashboard}/${ApiStatics.candidates.candidates}?sort=-createdAt&verified=${verified}&page=${page}&limit=${limit}`,
        config
      );

      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const getCandidateByUserId = createAsyncThunk(
  `Candidate/getCandidateByUserId`,
  async ({ id }) => {
    try {
      console.log("getCandidateByUserId");
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.profile.profile}/${ApiStatics.candidates.candidate}/${id}`,
        config
      );

      return {
        success: true,
        data: response.data.data.data,
      };
    } catch (error) {
      console.log("error", error);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

export const getAllCandidateRequests = createAsyncThunk(
  `Candidate/getAllCandidateRequests`,
  async ({ navigate, page, limit, sort }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.auth.auth}/${
          ApiStatics.userTypes.candidate
        }/${ApiStatics.requests.requests}?page=${page}&limit=${limit}${
          sort ? `&sort=${sort}` : ""
        }`,
        config
      );
      console.log("response", response);

      //   navigate("/dashboard");
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      //   toast.error(error.response.data.message);
      expireTokenHandler(error.response.data.message, navigate);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const searchCandidateRequests = createAsyncThunk(
  `Candidate/searchCandidateRequests`,
  async ({ navigate, page, limit, sort, text }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.auth.auth}/${
          ApiStatics.userTypes.candidate
        }/${
          ApiStatics.requests.requests
        }/search?search=${text}&page=${page}&limit=${limit}${
          sort ? `&sort=${sort}` : ""
        }`,
        config
      );
      console.log("response", response);

      //   navigate("/dashboard");
      return {
        success: true,
        data: response.data.data.data,
        total: response.data.total,
      };
    } catch (error) {
      console.log("error", error);
      //   toast.error(error.response.data.message);
      expireTokenHandler(error.response.data.message, navigate);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const approveCandidateRequest = createAsyncThunk(
  `Candidate/approveCandidateRequest`,
  async ({ id, name, email, linkedInUrl, toast }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.auth.auth}/${ApiStatics.userTypes.candidate}/${ApiStatics.requests.requests}/${id}/${ApiStatics.requests.accept}`,
        { name, email, linkedInUrl },
        config
      );
      console.log("success", toast);
      toast.success("Request approved successfully");
      setTimeout(() => {
        window.location.reload();
      }, 800);
      return {
        success: true,
        data: response,
      };
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      expireTokenHandler(error.response.data.message);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const approveCandidateByCandidateId = createAsyncThunk(
  `Candidate/approveCandidateByCandidateId`,
  async ({ id, toast }) => {
    try {
      const token = localStorage.getItem("token");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          // Accept: "*/*",
        },
      };
      let response = await axios.patch(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.profile.profile}/${ApiStatics.userTypes.candidate}/${id}`,
        { verified: true },
        config
      );
      console.log("success", toast.success);
      toast && toast.success("Candidate approved successfully");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      return {
        success: true,
        data: response,
      };
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      expireTokenHandler(error.response.data.message);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

const CandidateSlice = createSlice({
  name: "Candidate",
  initialState,
  reducers: {
    setCandidateType(state, { payload }) {
      state.selectedCandidateTab = payload.candidateType;
    },
    handleOpenDescription(state, { payload }) {
      console.log("id");
      const id = payload.id;
      const temp = [...state.openCards];
      console.log("id", id);
      if (temp.includes(id)) {
        const index = temp.indexOf(id);
        temp.splice(index, 1);
        state.openCards = temp;
      } else {
        temp.push(payload.id);
        state.openCards = temp;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCandidateRequests.pending, (state, action) => {
        state.candidateLoading = true;
        state.error = initialState.error;
      })
      .addCase(getAllCandidateRequests.rejected, (state, { payload }) => {
        state.candidateLoading = true;
        state.error = payload?.error;
      })
      .addCase(getAllCandidateRequests.fulfilled, (state, { payload }) => {
        state.candidateLoading = false;
        state.candidateRequests = payload.data;
        state.totalRequests = payload.total;
        state.success = true;
      });
    builder
      .addCase(searchCandidateRequests.pending, (state, action) => {
        state.candidateLoading = true;
        state.error = initialState.error;
      })
      .addCase(searchCandidateRequests.rejected, (state, { payload }) => {
        state.candidateLoading = true;
        state.error = payload?.error;
      })
      .addCase(searchCandidateRequests.fulfilled, (state, { payload }) => {
        state.candidateLoading = false;
        state.candidateRequests = payload.data;
        state.totalRequests = payload.total;
        state.success = true;
      })
      .addCase(approveCandidateRequest.pending, (state, action) => {
        state.candidateLoading = true;
        state.error = initialState.error;
      })
      .addCase(approveCandidateRequest.rejected, (state, { payload }) => {
        state.candidateLoading = false;
        // state.error = payload.error;
      })
      .addCase(approveCandidateRequest.fulfilled, (state, { payload }) => {
        state.candidateLoading = false;
        state.success = true;
      })
      .addCase(approveCandidateByCandidateId.pending, (state, action) => {
        state.candidateLoading = true;
        state.error = initialState.error;
      })
      .addCase(approveCandidateByCandidateId.rejected, (state, { payload }) => {
        state.candidateLoading = false;
        // state.error = payload.error;
      })
      .addCase(
        approveCandidateByCandidateId.fulfilled,
        (state, { payload }) => {
          state.candidateLoading = false;
          state.success = true;
        }
      )
      .addCase(getAllCandidates.pending, (state, action) => {
        state.candidateLoading = true;
        state.candidates = [];
        state.error = initialState.error;
      })
      .addCase(getAllCandidates.rejected, (state, { payload }) => {
        state.candidateLoading = true;
        state.error = payload.error;
      })
      .addCase(getAllCandidates.fulfilled, (state, { payload }) => {
        state.candidateLoading = false;
        state.candidates = payload.data;
        state.total = payload.total;
        state.success = true;
      })
      .addCase(getCandidateByUserId.pending, (state, action) => {
        state.candidateLoading = true;
        state.error = initialState.error;
      })
      .addCase(getCandidateByUserId.rejected, (state, { payload }) => {
        state.candidateLoading = true;
        state.error = payload.error;
      })
      .addCase(getCandidateByUserId.fulfilled, (state, { payload }) => {
        state.candidateLoading = false;
        state.currentCandidateData = payload.data;
        state.success = true;
      });
  },
});

export default CandidateSlice.reducer;
export const { setCandidateType, handleOpenDescription } =
  CandidateSlice.actions;
