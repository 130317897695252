import dashboard from "../Assets/Icons/table-layout.svg";
import people from "../Assets/Icons/users.svg";
import bag from "../Assets/Icons/u_bag.svg";
import gear from "../Assets/Icons/gear.svg";

export const capitalize = (s) => s && s[0]?.toUpperCase() + s.slice(1);

export const navLinks = [
  {
    label: "Dashboard",
    link: "/dashboard/1",
    icon: dashboard,
  },
  {
    label: "Candidates",
    link: "/dashboard/candidates/1",
    icon: people,
  },
  {
    label: "Employers",
    link: "/dashboard/employers",
    icon: bag,
  },
  {
    label: "Manage Jobs",
    link: "/dashboard/jobs/1",
    icon: gear,
  },
];
