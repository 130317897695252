import React from "react";
import { loginFieldData } from "../../../Utils/FormikFieldSkeleton";
import CustomForm from "../../../Components/Form";
import "./index.scss";
import logoText from "../../../Assets/Icons/logo_text.svg";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "../../../Redux/Slices/AuthSlice";

const LoginSignUp = () => {
  const { signupForm, loading } = useSelector((state) => state.AuthSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onFinish = (values) => dispatch(loginUser({ values, navigate, toast }));

  const FormBtn = ({ loading }) => (
    <Button
      className="auth-cta"
      type="primary"
      htmlType="submit"
      loading={loading}
    >
      Continue
    </Button>
  );
  const btnProps = {
    loading,
  };
  return (
    <div className="login-form-container">
      <img src={logoText} width="201px" alt="logo" />
      <Title level={1} className="font-medium welcome-stealth-text">
        Welcome to Stealth Jobs!
      </Title>
      <Paragraph>Confidentially Search For Your Next Leader</Paragraph>
      <CustomForm
        fields={loginFieldData}
        onFinish={onFinish}
        style={{ marginTop: "40px" }}
        FormBtn={FormBtn}
        btnProps={btnProps}
        initialValues={{ ...signupForm }}
      />

      {/* <FormBottom label1="" label2="" link="" /> */}
      {/* {isLogin ? (
        <FormBottom label1="New here?" label2="Sign up" link="/signup" />
      ) : (
        <FormBottom
          label1="Already have an account?"
          label2="Sign in"
          link="/"
        />
      )} */}
      <ToastContainer />
    </div>
  );
};

export default LoginSignUp;
