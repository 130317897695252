import React, { useEffect, useState } from "react";
import "./index.scss";
import CustomTable from "../../Components/Table";
import {
  allCandidatesColumns,
  underReviewCandidatesColumns,
} from "../../Utils/tableData";
import { useDispatch, useSelector } from "react-redux";
import { getAllCandidates } from "../../Redux/Slices/CandidateSlice";
import { useNavigate, useParams } from "react-router-dom";
import EditDialog from "../../Components/Dialog";
import "react-toastify/dist/ReactToastify.css";
import PreferenceDrawer from "./PreferenceDrawer";
const Candidates = () => {
  const { updatedLoading } = useSelector((state) => state.DashboardSlice);
  const { candidates, candidateLoading, total } = useSelector(
    (state) => state.CandidateSlice
  );
  const localStatus = localStorage.getItem("cadidateListStatus");

  const [status, setStatus] = useState(localStatus ? localStatus : "approved");
  // const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pageNumber } = useParams();

  const handleStatus = (e) => {
    // setPage(1);
    setStatus(e.target.value);
    localStorage.setItem("cadidateListStatus", e.target.value);
    navigate(`/dashboard/candidates/1`);
  };
  const pagination = {
    total,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    current: pageNumber,
    pageSize: limit,
    onChange: (newPage) => {
      // localStorage.setItem("candidatesPage", newPage);
      // setPage(newPage);
      navigate(`/dashboard/candidates/${newPage}`);
    },
    onShowSizeChange: (e, value) => setLimit(value),
  };

  useEffect(() => {
    // const localPage = Number(localStorage.getItem("candidatesPage"));
    // localPage ? setPage(localPage) : setPage(1);
    dispatch(
      getAllCandidates({
        navigate,
        verified: status === "approved",
        page: pageNumber,
        limit,
      })
    );
  }, []);
  useEffect(() => {
    // !localPage &&
    dispatch(
      getAllCandidates({
        navigate,
        verified: status === "approved",
        page: pageNumber,
        limit,
      })
    );
  }, [status, pageNumber, limit]);
  useEffect(() => {
    if (localStatus) {
      setStatus(localStatus);
    }
  }, []);
  return (
    <div className="candidates-container">
      <CustomTable
        title="Candidates List"
        dataSource={candidates}
        columns={
          status === "approved"
            ? allCandidatesColumns
            : underReviewCandidatesColumns
        }
        loading={candidateLoading}
        renderTabs
        status={status}
        handleStatus={handleStatus}
        pagination={pagination}
      />
      <EditDialog loading={updatedLoading} status={status} />
      {/* <ToastContainer /> */}
      <PreferenceDrawer />
    </div>
  );
};

export default Candidates;
