import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import building from "../Assets/Icons/ri_building-fill.svg";
import dropDown from "../Assets/Icons/dropDown.svg";

import "react-quill/dist/quill.snow.css";
// import "react-quill/dist/quill.core.css";
import ReactQuill from "react-quill";
import { currencyFormatter, currencyParser } from "./helpers";

export const formFieldToRender = ({
  name,
  type,
  placeholder,
  options,
  setExperienceLevel,
  customConfig,
  initialValues,
  isDisabled,
  boxSize,
  size,
  quillRef,
  quillModules,
  handleLocation,
  hidden,
  form,
  locations,
  locationSearchText,
  setLocationSearchText,
  searchValue,
  debounceFetcher,
  fetching,
  setSearchValue,
  handleJobFunctions,
}) => {
  const configChecker = () => {
    let counter = 0;
    let customOptions = [];
    customConfig?.map((obj) => {
      if (name === obj.name) {
        counter += 1;
        customOptions = obj.options;
      }
    });
    if (counter === 0) return options;
    else return customOptions;
  };

  const defaultSingleValueChecker = () => {
    if (name === "jobLocations") {
      return null;
    } else {
      if (initialValues) {
        return initialValues[name];
      } else return null;
    }
  };
  const handleFilterOnchange = (e, value) => {
    if (name === "experienceLevel") {
      setExperienceLevel(value?.label);
      form.setFieldsValue({ experienceLevel: value?.label });
    }
  };
  switch (type) {
    case "text":
      return (
        <Input
          placeholder={placeholder}
          size="large"
          name={name}
          disabled={isDisabled}
        />
      );

    case "password":
      return <Input.Password placeholder={placeholder} size="large" />;
    case "description":
      return (
        <ReactQuill
          ref={quillRef}
          theme="snow"
          bounds="#editor-container"
          modules={quillModules}
          // value={formik.values.content}
          // onChange={(value, e) => formik.setFieldValue("content", value)}
          style={{ height: "400px", marginBottom: "80px" }}
        />
      );
    case "password.linked":
      return (
        <div className="linked-password-container">
          <Input.Password placeholder={placeholder} size="large" />
          <Link to="/forgot-password">
            <Paragraph className="text-primary text-end mt-10">
              Forgot Password?
            </Paragraph>
          </Link>
        </div>
      );

    case "empty":
      return (
        <div
          style={{
            width: "100%",
          }}
        />
      );
    case "search":
      return (
        <Select
          showSearch
          labelInValue
          value={searchValue}
          filterOption={false}
          onSearch={debounceFetcher}
          loading={fetching}
          placeholder={placeholder}
          suffixIcon={<img src={dropDown} alt="drop-down" width="24px" />}
          notFoundContent={fetching ? <Spin size="small" /> : null}
          onChange={(newValue) => {
            setSearchValue(newValue);
          }}
          options={configChecker()}
          fetchOptions={configChecker()}
        />
      );
    case "select":
      return (
        <Select
          showSearch={name === "jobLocations"}
          placeholder={placeholder}
          options={configChecker()}
          defaultValue={defaultSingleValueChecker()}
          filterOption={(input, option) =>
            name === "jobLocations" &&
            option.value.toLowerCase().startsWith(input.toLowerCase())
          }
          size="large"
          suffixIcon={<img src={dropDown} alt="drop-down" width="24px" />}
          disabled={isDisabled}
          onChange={
            name === "jobFunctionIds"
              ? (e, v) => handleJobFunctions(e, v)
              : handleFilterOnchange
          }
          className={hidden && "hidden-field"}
        />
      );
    case "autocomplete":
      return (
        <AutoComplete
          value={locationSearchText}
          options={locationSearchText !== "" && options}
          // onSelect={onSelect}
          onSearch={(text) => setLocationSearchText(text)}
          filterOption={(input, option) =>
            name === "jobLocations" &&
            option.value.toLowerCase().startsWith(input.toLowerCase())
          }
          onSelect={(e) => {
            handleLocation(e);
            form.setFieldsValue({ jobLocations: [...locations] });
          }}
          placeholder={placeholder}
        />
      );

    case "checkbox":
      return (
        <Checkbox.Group
          style={{
            width: "100%",
          }}
          name={name}
          // className={"hidden-field"}
        >
          <Row
            style={{
              // display: "grid",
              // gridTemplateColumns: `repeat(${24 / boxSize.md}, 1fr)`,
              // rowGap: 10,
              // columnGap: 10,
              width: "100%",
            }}
            gutter={[8, 8]}
          >
            {configChecker().map(({ label, value }) => (
              <Col md={boxSize.md} key={label}>
                <div className="form-options-container">
                  {" "}
                  <Checkbox value={value}>{label}</Checkbox>
                </div>
              </Col>
            ))}
          </Row>
        </Checkbox.Group>
      );

    case "text.min":
      return (
        <InputNumber
          className={hidden && "hidden-field"}
          placeholder={placeholder}
          name={name}
          disabled={isDisabled}
          style={{ width: "100%" }}
          formatter={currencyFormatter}
          parser={currencyParser}
          prefix={
            <>
              <span className="min-max-text"> Min: </span>
              <span className="text-black dollar-sign"> $</span>
            </>
          }
        />
      );
    case "text.max":
      return (
        <InputNumber
          className={hidden && "hidden-field"}
          placeholder={placeholder}
          size="large"
          name={name}
          disabled={isDisabled}
          style={{ width: "100%" }}
          formatter={currencyFormatter}
          parser={currencyParser}
          prefix={
            <>
              <span className="min-max-text"> Max:</span>{" "}
              <span className="text-black dollar-sign"> $</span>
            </>
          }
        />
      );

    case "radio":
      return (
        <Radio.Group name={name}>
          <Row gutter={[8, 8]}>
            {options.map(({ label, value }) => (
              <Col md={boxSize.md}>
                <div className="form-options-container">
                  <Radio value={value}>{label}</Radio>
                </div>
              </Col>
            ))}
          </Row>
        </Radio.Group>
        // <Row>
        //   {options.map(({ label, value }) => (
        //     <Col md={boxSize.md} className="form-options-container">
        //       <div className=""></div>
        //       <Radio name={value}>{label}</Radio>
        //     </Col>
        //   ))}
        // </Row>
      );

    default:
      return;
  }
};

export const cardPreferencesRender = (
  candidateJob,
  preferencesArray,
  preferences,
  isJob
) => {
  if (!isJob) {
    return preferences.map(
      ({ label, value }) =>
        // <Col md={2}>   value &&
        value &&
        value !== "N/A" && (
          <div className="candidate-job-preference-chip">
            <Paragraph className="preference-chip-value reset-margin">
              {value}
            </Paragraph>
          </div>
        )
      // </Col>
    );
  } else if (!candidateJob) {
    return preferencesArray.map(
      ({ label, value }) =>
        // <Col md={2}>
        value &&
        value !== "N/A" && (
          <div className="job-preference-chip">
            <Paragraph className="preference-chip-label">{label}</Paragraph>
            <Paragraph className="preference-chip-value reset-margin">
              {value}
            </Paragraph>
          </div>
        )
      // </Col>
    );
  } else {
    return preferencesArray.map(
      ({ label, value }) =>
        // <Col md={2}>
        value &&
        value !== "N/A" && (
          <div className="candidate-job-preference-chip">
            <Paragraph className="preference-chip-value reset-margin">
              {value}
            </Paragraph>
          </div>
        )
      // </Col>
    );
  }
};

export const cardDescriptionRenderer = (
  candidateJob,
  description,
  isJob,
  experience,
  checkCardState,

  viewMore,
  setViewMore
) => {
  if (!isJob) {
    console.log("customExperience in card", experience);
    const customExperience = checkCardState() ? experience : [experience[0]];
    return customExperience.map(
      ({
        noOfEmployees,
        arr,
        jobFunction,
        companyName,
        joiningDate,
        leavingDate,
        responsibilities,
      }) => (
        // <Col md={2}>
        <div className="job-card-body candidate-experience-container ">
          <div className="candidate-exp-header d-flex align-center">
            <div className="candidate-experience-comapny-tag ">
              <Paragraph className="reset-margin">{arr} ARR</Paragraph>
            </div>
            <div className="candidate-experience-comapny-tag ">
              {" "}
              <Paragraph className="reset-margin">
                {noOfEmployees} employees
              </Paragraph>
            </div>
          </div>
          <div className="candidate-exp-lower-header">
            <div className="experience-building-wrapper">
              <img src={building} width="24px" alt="building" />
            </div>
            <div className="experience-comapany-name-with-designation">
              <Paragraph className="reset-margin name-function">
                {jobFunction}({companyName})
              </Paragraph>
              <Paragraph className=" reset-margin experience-dates">
                {joiningDate}-{leavingDate ? leavingDate : "Present"}
              </Paragraph>
            </div>
          </div>
          <div className="responsibilities-container">
            <ul>
              {" "}
              {responsibilities?.map((responsibility) => (
                <li className="responsibility-line">{responsibility}</li>
              ))}
            </ul>
          </div>
        </div>
        // </Col>
      )
    );
  }
  if (!candidateJob) {
    const handleViewMore = () => setViewMore(!viewMore);

    const reducedDescription = description && parse(description?.slice(0, 600));
    const textToShow = viewMore ? parse(description) : reducedDescription;
    const renderViewMore = () => description?.length > 600;
    return (
      <div className="job-card-body">
        <Paragraph className="about-the-job-text">About the job</Paragraph>
        <div>{textToShow}</div>

        {renderViewMore() && (
          <Button
            onClick={handleViewMore}
            type="text"
            style={{ color: "#5D5BF6" }}
          >
            ... view {viewMore ? "less" : "more"}
          </Button>
        )}
      </div>
    );
  }
};
