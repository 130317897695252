import React from "react";
import "./index.scss";
import ross from "../../../Assets/Images/ross.svg";
import dwnArrow from "../../../Assets/Icons/arr-dwn.svg";
import Paragraph from "antd/es/typography/Paragraph";
import { capitalize } from "../../../Utils/staticData";
import ProfilePopOverContent from "./ProfilePopoverContent";
import { Popover } from "antd";

const TopBar = () => {
  const username = localStorage.getItem("userName");

  return (
    <div className="top-bar-container">
      <Popover
        content={ProfilePopOverContent}
        trigger="click"
        className="cursor"
      >
        <div className="nav-profile-container">
          <div className="profile-pic-wrapper">
            <img src={ross} className="avatar" alt="avatar" />
          </div>

          <Paragraph className="reset-margin">{username}</Paragraph>
          <img src={dwnArrow} alt="down-arrow" width="36px" />
        </div>
      </Popover>
      {/* <div className="nav-profile-container">
        <div className="profile-pic-wrapper">
          <img src={ross} className="avatar" alt="avatar" />
        </div>
        <Paragraph className="reset-margin">{capitalize(username)}</Paragraph>
        <img src={dwnArrow} alt="down-arrow" width="36px" />
      </div> */}
    </div>
  );
};

export default TopBar;
