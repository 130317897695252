import React from "react";
import { ReactComponent as Logout } from "../../../Assets/Icons/logout.svg";
import { useNavigate } from "react-router-dom";

const ProfilePopOverContent = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  return (
    <>
      <div className="d-flex align-center cursor" onClick={handleLogout}>
        <Logout />
        <p style={{ marginLeft: "8px" }}>Logout</p>
      </div>
    </>
  );
};

export default ProfilePopOverContent;
