import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

import userpool from "../../Config/userpool";
import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import { ApiStatics } from "../../Utils/constants";

export const loginUser = createAsyncThunk(
  `auth/login`,
  async ({ values, navigate, toast }) => {
    //   let { email, password } = data;
    const user = new CognitoUser({
      Username: values.workEmail,
      Pool: userpool,
    });
    const authDetails = new AuthenticationDetails({
      Username: values.workEmail,
      Password: values.password,
    });

    try {
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("inside login");
          const token = data.getIdToken();
          const jwt = token?.getJwtToken();
          localStorage.setItem("token", jwt);
          const decoded = jwtDecode(jwt);
          localStorage.setItem("userName", decoded.name);
          navigate("/dashboard/1");

          return {
            success: true,
          };

          // const decoded = jwtDecode(jwt);
          // console.log("decoded token", decoded);
          // dispatch(getMyRoles());
        },
        onFailure: (err) => {
          // setLoading(false);
          toast.error(err.message);
          // notification.error({
          //   message: "Incorrect username or password",
          //   placement: "topRight",
          // });
        },
        newPasswordRequired: (data) => {
          //  setLoading(false);
          //  notification.error({
          //    message: "New Password Required",
          //    placement: "topRight",
          //  });
        },
      });
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);
export const signUp = createAsyncThunk(
  `auth/signUp`,
  async ({ values, navigate, toast }) => {
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BACKEND_URL}/${ApiStatics.auth.auth}/${ApiStatics.userTypes.employer}/${ApiStatics.auth.signup}`,
        values
      );
      console.log("response", response);

      const token = response.data.data.token;
      console.log("token", token);
      localStorage.setItem("token", token);

      navigate("/dashboard/1");
      return {
        success: true,
        // data: response.data.data.payload,
        // token: response.data.data.jwtToken,
      };
    } catch (error) {
      console.log("error", error);
      toast.error(error.response.data.message);
      return {
        success: false,
        error: error.message,
      };
    }
  }
);

const initialState = {
  sessionAvailable: false,
  signupForm: {},
  loading: false,
  userName: "",
  userId: "",
  error: {
    result: false,
    message: "",
  },
  success: false,
};

const AuthSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUsername(state, { payload }) {
      state.userName = payload.userName;
    },
    setSignupForm(state, { payload }) {
      state.signupForm = payload.form;
    },
    setAvailableSession(state, action) {
      state.sessionAvailable = true;
    },
    clearUserSession(state, action) {
      window.location.href = "/";
      state = initialState;
      localStorage.clear();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.loading = true;
        state.error = initialState.error;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.loading = false;
        state.sessionAvailable = true;
        state.success = true;
      });

    builder
      .addCase(signUp.fulfilled, (state, action) => {
        if (!action.payload.success) {
          state.loading = false;
          state.error.result = true;
          state.error.message = action.payload.error;
          return;
        }

        let { data, token } = action.payload;
        console.log("token", token);

        state.loading = false;
        state.sessionAvailable = true;

        state.success = true;
      })
      .addCase(signUp.pending, (state, action) => {
        state.loading = true;
        state.error = initialState.error;
      });
  },
});

export default AuthSlice.reducer;
export const {
  setAvailableSession,
  clearUserSession,
  setSignupForm,
  setUsername,
} = AuthSlice.actions;
