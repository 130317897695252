const ThemeConfig = {
  components: {
    Typography: {
      fontSizeHeading1: 40,
      fontSizeHeading2: 32,
      fontSizeHeading3: 24,
      fontSizeHeading4: 20,
      fontSize: 16,
      // lineHeightHeading1: "40px",
      // lineHeightHeading2: 4.0,
      // lineHeightHeading3: 3.2,
      // lineHeightHeading4: 2.8,
      fontWeightStrong: 700,
      colorText: "rgb(19, 26, 41)",
      colorTextHeading: "#4B4B4B",
      colorWarning: "rgb(255, 173, 13)",
      // colorPrimary: "rgb(93, 91, 246)",
      colorError: "rgb(246, 76, 76)",
      colorSuccess: "rgb(71, 184, 129)",
      colorLink: "rgb(59, 130, 246)",
    },
    Button: {
      colorPrimary: "linear-gradient(180deg, #5D5BF6 0%, #4746CB 100%)",
      primaryShadow: "none",
      borderRadius: 10,
      borderRadiusLG: 10,
      contentFontSize: 16,
      contentFontSizeLG: 18,
      contentFontSizeSM: 14,
      colorPrimaryHover:
        "linear-gradient(180deg, #5D5BF6 0%, #4746CB 100%),linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08))",
      defaultBorderColor: "#E1E1E1",
      dangerShadow: "none",
      defaultShadow: "none",
      defaultColor: "#4B4B4B",
      textHoverBg: "rgb(245, 245, 245)",
      colorText: "rgb(75, 75, 75)",
      fontWeight: 500,
      colorPrimaryActive:
        "linear-gradient(180deg, #5D5BF6 0%, #4746CB 100%),linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
      defaultActiveColor: "rgb(67, 65, 226)",
      defaultActiveBorderColor: "rgb(67, 65, 226)",
      defaultHoverBorderColor: "rgb(67, 65, 226)",
      defaultHoverColor: "rgb(67, 65, 226)",
      colorTextDisabled: "rgb(202, 202, 202)",
      borderColorDisabled: "rgb(225, 225, 225)",
    },
    Input: {
      activeBg: "rgb(255, 255, 255)",
      colorBgContainer: "#fff",
      // colorBorder: "rgba(217, 217, 217, 0)",

      activeShadow: "none",
      errorActiveShadow: "none",
      warningActiveShadow: "none",
      borderRadius: 10,
      inputFontSizeLG: 18,
      hoverBorderColor: "rgb(93, 91, 246)",
      colorError: "rgb(246, 76, 76)",
      colorBgContainerDisabled: "rgb(225, 225, 225)",
      colorTextDisabled: "rgb(142, 142, 142)",
      colorTextPlaceholder: "#8E8E8E",
      activeBorderColor: "#5D5BF6",
      paddingInline: 16,
      paddingInlineLG: 16,
    },
    Checkbox: {
      colorPrimary: "#5D5BF6",
      colorPrimaryHover: "#4341E2",
      fontSize: 18,
      controlInteractiveSize: 24,
    },
    Radio: {
      colorPrimary: "#5D5BF6",
      colorPrimaryHover: "#4341E2",
      fontSize: 18,
      dotSize: 11,
      radioSize: 24,
    },
    Select: {
      fontSize: 18,
      fontSizeLG: 18,
      colorBgContainer: "#fff",
      colorTextPlaceholder: "#8E8E8E",
      activeBorderColor: "#5D5BF6",
      optionPadding: "5px 16px",
      paddingSM: 16,
    },
    Table: {
      headerColor: "#8E8E8E",
      fontSize: 14,
    },
  },
};

export default ThemeConfig;
