import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";

const BackContinue = () => {
  const navigate = useNavigate();
  const handleBack = () => navigate(-1);
  return (
    <div className="submit-with-back">
      <Button
        shape="circle"
        icon={<LeftOutlined style={{ color: "#4B4B4B" }} />}
        className="auth-back-btn"
        onClick={handleBack}
      />
      <Button
        className="auth-cta"
        type="primary"
        htmlType="submit"
        style={{ width: "90%", marginTop: 0 }}
      >
        Continue
      </Button>
    </div>
  );
};

export default BackContinue;
