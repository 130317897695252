import { Divider, Drawer, Skeleton } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closePreferenceDrawer } from "../../Redux/Slices/DialogSlice";
import ProfileSection from "./ProfileSection";
import { getCandidateByUserId } from "../../Redux/Slices/CandidateSlice";
import linkedINblue from "../../Assets/Icons/Linkedin-blue.svg";
import userIcon from "../../Assets/Icons/user-pen-alt.svg";
import {
  currencyFormatter,
  emptyArrayDataHandler,
  noDataHandler,
  noinudustryTypeHandler,
} from "../../Utils/helpers";
import Paragraph from "antd/es/typography/Paragraph";
const PreferenceDrawer = () => {
  const { preferenceDrawer, selectedId } = useSelector(
    (state) => state.DialogSlice
  );
  const { currentCandidateData, candidateLoading } = useSelector(
    (state) => state.CandidateSlice
  );
  const dispatch = useDispatch();
  const handleCloseDrawer = () => dispatch(closePreferenceDrawer());
  useEffect(() => {
    dispatch(getCandidateByUserId({ id: selectedId }));
  }, [preferenceDrawer]);

  const personalInfoFields = [
    {
      label: "Name",
      value: currentCandidateData?.candidate?.fullName,
    },
    {
      label: "Linkedin Profile",
      value: (
        <div className="linkedin-link-container">
          <img src={linkedINblue} alt="linkedin" width="24px" />
          <a
            href={currentCandidateData?.candidate?.linkedinUrl}
            target="blank"
            rel="noreferer noopener"
          >
            {currentCandidateData?.candidate?.fullName}
          </a>
        </div>
      ),
    },
  ];
  console.log(
    "currentCandidateData?.candidate?.jobSearchType",
    currentCandidateData
  );
  const preferredJobTypesField = [
    {
      label: "Where are you in your job search",
      value: noDataHandler(
        currentCandidateData?.candidate?.preferences?.jobSearchType
      ),
    },
    {
      label: "Location Type",
      value: noDataHandler(
        currentCandidateData?.candidate?.preferences?.locationType?.join(", ")
      ),
    },
    {
      label: "Employment Type",
      value: noDataHandler(
        currentCandidateData?.candidate?.preferences?.employmentType?.join(", ")
      ),
    },
    {
      label: "Salary Range",
      value:
        currentCandidateData?.candidate?.preferences?.minSalary &&
        currentCandidateData?.candidate?.preferences?.maxSalary
          ? `$${currencyFormatter(
              currentCandidateData?.candidate?.preferences?.minSalary
            )} -
        $${currencyFormatter(
          currentCandidateData?.candidate?.preferences?.maxSalary
        )}`
          : "N/A",
    },
    {
      label: "Primary Job Function",
      value: noDataHandler(
        currentCandidateData?.candidate?.preferences?.jobFunction?.name
      ),
    },
    {
      label: "Experience Level",
      value: noDataHandler(
        currentCandidateData?.candidate?.preferences?.experienceLevel
      ),
    },
  ];
  const preferredJobTtitleFields = [
    {
      label: "What industries are you interested in working in",
      value: noinudustryTypeHandler(
        currentCandidateData?.candidate?.preferences?.industryTypes
      ),
    },
    {
      label: "Preferred Job locations",
      value: currentCandidateData?.candidate?.preferences?.locations,
    },
  ];
  const preferredCompanies = [
    {
      label: "Number of Employees in the Company",
      value: noDataHandler(
        currentCandidateData?.candidate?.preferences?.numberOfEmployees?.join(
          ", "
        ) + " Employees"
      ),
    },
    {
      label: "What stage company do you want to work with",
      value: noDataHandler(
        currentCandidateData?.candidate?.preferences?.companyStage?.join(", ")
      ),
    },
  ];
  return (
    <Drawer
      title={
        <div className="d-flex align-center">
          <img src={userIcon} width="40px" alt="lg" />
          <Paragraph className="profile-dialog-heading text-black">
            Candidate Preferences
          </Paragraph>
        </div>
      }
      placement="right"
      onClose={handleCloseDrawer}
      open={preferenceDrawer}
      width={1000}
    >
      {candidateLoading ? (
        <div className="preference-loading-container">
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </div>
      ) : (
        <>
          <ProfileSection
            fields={personalInfoFields}
            heading="Personal Information"
          />
          <Divider className="custom-divider" />
          <ProfileSection
            fields={preferredJobTypesField}
            heading="Preferred Job Types"
          />
          <Divider className="custom-divider" />
          <ProfileSection
            fields={preferredJobTtitleFields}
            heading="Preferred Job Titles"
            isChips
          />
          <Divider className="custom-divider" />
          <ProfileSection
            fields={preferredCompanies}
            heading="Preferred Company"
          />
        </>
      )}
    </Drawer>
  );
};

export default PreferenceDrawer;
