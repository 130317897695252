import React from "react";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import CustomForm from "../../../Components/Form";
import { useLocation, useNavigate } from "react-router-dom";
import {
  forgotFields,
  resetPasswordFields,
} from "../../../Utils/FormikFieldSkeleton";
import "./index.scss";
import BackContinue from "../../../Components/Form/BackContinue";
import logoText from "../../../Assets/Icons/logo_text.svg";

const ForgotPassword = () => {
  const { pathname } = useLocation();
  const isForgot = pathname === "/forgot-password";
  const navigate = useNavigate();

  const onFinish = (values) =>
    isForgot ? navigate("/reset-password") : console.log(values);
  return (
    <div className="forgot-password-container">
      <img src={logoText} width="201px" alt="logo" />
      <Title level={1} className="font-medium text-black">
        Recover your account
      </Title>
      <Paragraph>
        Enter the email associated with your account, and we'll send you a
        password reset link
      </Paragraph>
      <CustomForm
        fields={isForgot ? forgotFields : resetPasswordFields}
        onFinish={onFinish}
        style={{ marginTop: "40px" }}
        FormBtn={BackContinue}
      />
    </div>
  );
};

export default ForgotPassword;
