import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import "./index.scss";

const DashboardLayout = () => {
  const { pathname } = useLocation();
  console.log("pathname", pathname);
  const isAddJob = () =>
    pathname === "/dashboard/jobs/add-job" ||
    pathname === "/dashboard/jobs/preview-job";
  return (
    <div className="d-flex">
      <NavBar />
      <div className="dashboard-layout-right">
        <TopBar />
        <div className={isAddJob() ? "add-internal" : "internal-container"}>
          {" "}
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
