import React from "react";
import { Typography } from "antd";
import arrow from "../../Assets/Icons/increment.svg";
import { ReactSVG } from "react-svg";

const StatCard = ({ name, value, increment, img, decrement }) => {
  const { Text } = Typography;
  return (
    <div className="stat-crad-container" style={{ minWidth: 150 }}>
      <Text className="stat-name">{name}</Text>

      <div className="d-flex align-end stat-card-lower">
        <div className="stat-data-container">
          <div className="d-flex align-center ">
            <Text className="stat-number">{value}</Text>
            {/* <div
              className={`stat-arrow-container d-flex justify-center ${
                !decrement ? "increasing-stat" : "decreasing-stat"
              }`}
            >
              <ReactSVG
                src={arrow}
                width="11px"
                alt="increment"
                className={decrement && "decreasing-arrow"}
              />
            </div> */}
            {/* <Text
              className={`percentage ${
                decrement ? "decrement-value" : "increment-value"
              }`}
            >
              {increment ? increment : `-${decrement}`}%
            </Text> */}
          </div>

          {/* <Text className="comapared-text">Compared to Last week</Text> */}
        </div>
        {/* <img src={img} alt="graph" width="93px" /> */}
      </div>
    </div>
  );
};

export default StatCard;
