import tick from "../Assets/Icons/check.svg";
import cross from "../Assets/Icons/xmark.svg";
import edit from "../Assets/Icons/pencil.svg";
import linkedInblue from "../Assets/Icons/Linkedin-blue.svg";
import eye from "../Assets/Icons/eye.svg";
import Store from "../Redux/store";
import {
  approveCandidateByCandidateId,
  approveCandidateRequest,
  getAllCandidateRequests,
} from "../Redux/Slices/CandidateSlice";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  handleOpenDialog,
  openJobDrawer,
  openPreferenceDrawer,
} from "../Redux/Slices/DialogSlice";
import { Tag } from "antd";
import { arrayReducer, dateFormatter } from "./helpers";
import { approveJob, rejectJob, updateJob } from "../Redux/Slices/JobSlice";

export const allCandidatesColumns = [
  {
    title: "Name",
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (data, record) => record.user.email,
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    // render: (data, record) => record.user.email,
  },
  {
    title: "Primary Job Function",
    dataIndex: "preferences",
    render: (preferences) => preferences?.jobFunction?.name,
  },
  {
    title: "Experience Level",
    dataIndex: "preferences",
    render: (preferences) => preferences?.experienceLevel,
  },
  {
    title: "Status",
    dataIndex: "verified",
    key: "verified",
    render: (verified) =>
      verified ? (
        <Tag color="green">Approved</Tag>
      ) : (
        <Tag color="red">Under Review</Tag>
      ),
  },
  {
    title: "Preferences",
    align: "center",
    key: "preferences",
    render: (data) => (
      <img
        src={eye}
        width="20px"
        alt="edit"
        className="ml-10 cursor"
        onClick={() =>
          Store.dispatch(
            openPreferenceDrawer({
              id: data.user.id,
            })
          )
        }
      />
    ),
  },
  {
    title: "Linkedin",
    dataIndex: "linkedinUrl",
    align: "center",
    key: "linkedinUrl",
    render: (url) => (
      <a href={url} target="_blank" rel="noreferrer">
        <img src={linkedInblue} width="20px" alt="tick" />
      </a>
    ),
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    render: (data) => (
      <div>
        <img
          src={edit}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              handleOpenDialog({
                id: data.id,
                type: "candidatePreference",
                fields: { ...data.preferences, id: data.id },
              })
            )
          }
        />
        <ToastContainer />
      </div>
    ),
  },
];
export const underReviewCandidatesColumns = [
  {
    title: "Name",
    // width: 130,
    dataIndex: "fullName",
    key: "fullName",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    width: 50,
    render: (data, record) => record.user.email,
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    // render: (data, record) => record.user.email,
  },
  {
    title: "Primary Job Function",
    dataIndex: "preferences",
    width: 250,
    render: (preferences) => preferences?.jobFunction?.name,
  },
  {
    title: "Experience Level",
    dataIndex: "preferences",
    render: (preferences) => preferences?.experienceLevel,
  },
  {
    title: "Status",
    dataIndex: "verified",
    key: "verified",
    render: (verified) => <Tag className="review-tag">Under Review</Tag>,
  },
  {
    title: "Preferences",
    align: "center",
    key: "preferences",
    render: (data) => (
      <img
        src={eye}
        width="20px"
        alt="edit"
        className="ml-10 cursor"
        onClick={() =>
          Store.dispatch(
            openPreferenceDrawer({
              id: data.user.id,
            })
          )
        }
      />
    ),
  },
  {
    title: "Linkedin",
    dataIndex: "linkedinUrl",
    align: "center",
    key: "linkedinUrl",
    render: (url) => (
      <a href={url} target="_blank" rel="noreferrer">
        <img src={linkedInblue} width="20px" alt="tick" />
      </a>
    ),
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    width: 100,
    render: (data) => (
      <div>
        <img
          src={tick}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              approveCandidateByCandidateId({
                id: data.id,
                toast,
              })
            )
          }
        />
        <img
          src={edit}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              handleOpenDialog({
                id: data.id,
                type: "candidatePreference",
                fields: { ...data.preferences, id: data.id },
              })
            )
          }
        />
        <ToastContainer />
      </div>
    ),
  },
];

export const candidatesList = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: (a, b) => a.name - b.name,
    // onFilter: (value, record) => record.name.indexOf(value) === 0,
    // sorter: (a, b, c) =>
    //   Store.dispatch(getAllCandidateRequests({ field: "name", order: c })),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    sorter: (a, b) => a.email - b.email,
  },
  {
    title: "Sign Up Date",
    dataIndex: "createdAt",
    key: "createdAt",
    sorter: (a, b) => console.log(""),
    render: (date) => dateFormatter(date),
  },
  {
    title: "Primary Job Function",
    dataIndex: "jobFunction",
    sorter: (a, b) => console.log(""),
    render: (jobFunction) => jobFunction?.name,
  },
  {
    title: "Experience Level",
    dataIndex: "experienceLevel",
    key: "experienceLevel",
    sorter: (a, b) => console.log(""),
  },

  {
    title: "Linkedin",
    dataIndex: "linkedinUrl",
    align: "center",
    key: "linkedinUrl",
    render: (url) => (
      <a href={url} target="_blank" rel="noreferrer">
        <img src={linkedInblue} width="20px" alt="tick" />
      </a>
    ),
  },
  {
    title: "Action",
    key: "action",
    align: "center",
    render: (data) => (
      <div className="d-flex align-center justify-center">
        <img
          src={tick}
          width="20px"
          alt="tick"
          className="cursor"
          onClick={() =>
            Store.dispatch(
              approveCandidateRequest({ id: data.id, toast, ...data })
            )
          }
        />
        <img
          src={edit}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              handleOpenDialog({
                id: data.id,
                type: "experienceLevel",
                fields: data,
              })
            )
          }
        />
        <ToastContainer />
      </div>
    ),
  },
];

export const employersList = [
  {
    title: " Name",
    dataIndex: "user",
    key: "user",
    render: (user) => user.name,
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Email",
    dataIndex: "workEmail",
    key: "workEmail",
  },

  {
    title: "Linkedin",
    dataIndex: "linkedinUrl",
    key: "linkedinUrl",
    align: "center",
    render: (url) => (
      <a href={url} target="_blank" rel="noreferrer">
        <img src={linkedInblue} width="20px" alt="tick" />
      </a>
    ),
  },
];
export const candidateData = [];

export const allApprovedJobsColumns = [
  {
    title: "User Name",
    render: (data) =>
      data?.employer?.user?.name
        ? data?.employer?.user?.name
        : localStorage.getItem("userName"),
  },
  {
    title: "Job Title",
    dataIndex: "jobTitle",
    key: "jobTitle",
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Primary Job Functions",
    render: (data) => arrayReducer(data?.jobFunctions),
  },
  {
    title: "Experience Level",
    render: (data) =>
      data?.allowedExperienceLevels?.map((level) => level.name)?.join(", "),
  },
  {
    title: "Matched/Interested Count",
    render: (data) => `${data?.matchedCount}/${data?.interestedCount}`,
  },
  {
    title: "Date Posted",
    render: ({ createdAt }) => dateFormatter(createdAt),
    width: 120,
  },
  {
    title: "Status",
    dataIndex: "verified",
    key: "verified",
    render: (verified) => <Tag color="green">Approved</Tag>,
  },
  {
    title: "Action",
    render: (data) => (
      <>
        <img
          src={eye}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              openJobDrawer({
                job: data,
              })
            )
          }
        />
      </>
    ),
  },
];
export const allPendingJobsColumns = [
  {
    title: "User Name",
    render: (data) =>
      data?.employer?.user?.name
        ? data?.employer?.user?.name
        : localStorage.getItem("userName"),
  },
  {
    title: "Job Title",
    dataIndex: "jobTitle",
    key: "jobTitle",
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Primary Job Functions",
    render: (data) => arrayReducer(data?.jobFunctions),
  },
  {
    title: "Experience Level",
    render: (data) =>
      data?.allowedExperienceLevels?.map((level) => level.name)?.join(", "),
  },
  {
    title: "Matched/Interested Count",
    render: (data) => `${data?.matchedCount}/${data?.interestedCount}`,
  },
  {
    title: "Date Posted",
    width: 120,
    render: ({ createdAt }) => dateFormatter(createdAt),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (verified) => <Tag className="review-tag">Under Review</Tag>,
  },
  {
    title: "Action",
    width: 150,
    render: (data) => (
      <div className="d-flex align-center">
        <img
          src={tick}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              approveJob({
                id: data.id,
                toast,
              })
            )
          }
        />
        <img
          src={cross}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              rejectJob({
                id: data.id,
                toast,
              })
            )
          }
        />
        <img
          src={eye}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              openJobDrawer({
                job: data,
              })
            )
          }
        />
      </div>
    ),
  },
];
export const allRejectedJobsColumns = [
  {
    title: "User Name",
    render: (data) =>
      data?.employer?.user?.name
        ? data?.employer?.user?.name
        : localStorage.getItem("userName"),
  },
  {
    title: "Job Title",
    dataIndex: "jobTitle",
    key: "jobTitle",
  },
  {
    title: "Company Name",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "Primary Job Functions",
    render: (data) => arrayReducer(data?.jobFunctions),
  },
  {
    title: "Experience Level",
    render: (data) =>
      data?.allowedExperienceLevels?.map((level) => level.name)?.join(", "),
  },
  {
    title: "Matched/Interested Count",
    render: (data) => `${data?.matchedCount}/${data?.interestedCount}`,
  },
  {
    title: "Date Posted",
    width: 120,
    render: ({ createdAt }) => dateFormatter(createdAt),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (verified) => <Tag color="red">Rejected</Tag>,
  },
  {
    title: "Action",
    width: 150,
    render: (data) => (
      <div className="d-flex align-center">
        <img
          src={eye}
          width="20px"
          alt="edit"
          className="ml-10 cursor"
          onClick={() =>
            Store.dispatch(
              openJobDrawer({
                job: data,
              })
            )
          }
        />
      </div>
    ),
  },
];
