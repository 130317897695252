import { Button, Card, Switch, Tooltip } from "antd";
import React, { useState } from "react";
import "./index.scss";
import building from "../../Assets/Icons/ri_building-fill.svg";
import human from "../../Assets/Icons/solar_user-bold-duotone.svg";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import pin from "../../Assets/Icons/pin.svg";
import circleInfo from "../../Assets/Icons/circle-information.svg";
import line from "../../Assets/Images/line.svg";
import arrowDown from "../../Assets/Icons/chevron-down.svg";
import {
  cardPreferencesRender,
  cardDescriptionRenderer,
  shouldRenderMiniatureLine,
} from "../../Utils/renderer";
import { useDispatch, useSelector } from "react-redux";
import { ReactSVG } from "react-svg";
import { handleOpenDescription } from "../../Redux/Slices/CandidateSlice";
import { useLocation } from "react-router-dom";
import {
  checkCompanyInfo,
  currencyFormatter,
  selectectValueChecker,
} from "../../Utils/helpers";
import blueInfo from "../../Assets/Icons/circle-information-blue.svg";
import Typography from "antd/es/typography/Typography";

const MultiCard = ({
  id,
  HeaderAction,
  jobLocations,
  jobTitle,
  isJob,
  totalInterestedCandidates,
  minSalary,
  maxSalary,
  locationType,
  employmentType,
  industryTypeId,
  allowedExperienceLevels,
  jobFunctionId,
  industryType,
  experienceLevel,
  jobFunction,
  companyArr,
  numberOfEmployees,
  companyStage,
  preview,
  jobDescription,
  candidateJob,
  handleClick,
  interestedCount,
  acceptedCount,
  appliedCount,
  name,
  preferences,
  experience,
  isInterested,
  companyName,
  state,
  country,
  candidateExperiences,
  requestId,
  fullName,
  loading,
  stealthMode,
  candidate,
  matchedParams,
  style,
  jobFunctionName,
  slug,
  jobFunctionIds,
  jobFunctions,
}) => {
  const { selectedJob, industryTypes, experiences } = useSelector(
    (state) => state.JobSlice
  );
  const { openCards, interestedLoadingId } = useSelector(
    (state) => state.CandidateSlice
  );

  const [isChecked, setChecked] = useState(false);
  const onChange = () => setChecked(!isChecked);
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const toggleExperience = () => dispatch(handleOpenDescription({ id }));
  const checkCardState = () => {
    if (openCards.includes(id)) {
      return true;
    } else return false;
  };
  // if (customExperience?.length > 1) setCustomExperience(oneExperience);
  // else setCustomExperience(experience);
  // };

  const jobDetails = {
    id,
    jobLocations,
    jobTitle,
    totalInterestedCandidates,
    minSalary,
    maxSalary,
    locationType,
    employmentType,
    industryTypeId,
    allowedExperienceLevels,
    jobFunctionId,
    companyArr,
    numberOfEmployees,
    companyStage,
    jobDescription,
    interestedCount,
    acceptedCount,
    preferences,
    companyName,
    experiences,
    stealthMode,
    slug,
    jobFunctionIds,
  };
  const [viewMore, setViewMore] = useState(false);
  const preferencesArray = [
    {
      label: "Salary",
      value:
        minSalary && maxSalary
          ? `$${minSalary && currencyFormatter(minSalary)} - $${
              maxSalary && currencyFormatter(maxSalary)
            }`
          : "N/A",
      // value: `$${currencyFormatter(minSalary)} - $${currencyFormatter(
      //   maxSalary
      // )}`,
    },
    {
      label: "Location type",
      value: locationType?.length > 0 ? locationType?.join(" / ") : "N/A",
    },
    {
      label: "Employment type",
      value: employmentType?.length > 0 ? employmentType?.join(" / ") : "N/A",
    },
    {
      label: "Industry",
      value: !preview
        ? industryType?.name
        : selectectValueChecker(industryTypeId, industryTypes),
    },
    {
      label: "Experience level",
      value: !preview
        ? allowedExperienceLevels?.map((level) => level.name)?.join(", ")
        : allowedExperienceLevels
            ?.map((level) => selectectValueChecker(level, experiences))
            ?.filter((val) => val != null)
            ?.join(", "),
    },
    {
      label: "Primary job function",
      value: preview
        ? jobFunctionIds?.map(({ label }) => label)?.join(", ")
        : jobFunctions?.map((func) => func.name).join(","),
      // value: !preview
      //   ? jobFunctionIds?.map(({ label }) => label)?.join(", ")
      //   : selectectValueChecker(jobFunctionIds, jobFunctions),
    },
    {
      label: "Company ARR",
      value: companyArr ? companyArr : "N/A",
    },
    {
      label: "No. of employees",
      value: numberOfEmployees ? numberOfEmployees : "N/A",
    },
    {
      label: "Company stage",
      value: companyStage ? companyStage : "N/A",
    },
  ];
  const handleCardClick = () => {
    // setCustomExperience([]);
    handleClick(jobDetails);
  };

  const candidateLocationRender = () => {
    if (state && country) {
      return `${state}, ${country}`;
    } else if (state) {
      return `${state}`;
    } else if (country) {
      return `${country}`;
    }
  };
  const renderName = () => {
    if (!isJob) {
      if (isInterested) {
        if (!fullName) {
          return "Confidential";
        } else return fullName;
      } else return fullName;
    } else if (isJob) {
      return (
        <span>
          {jobTitle}{" "}
          {
            <span className={preview && isChecked && "blur"}>
              ({preview && isChecked ? "confidential" : companyName})
            </span>
          }
        </span>
      );
    }
  };
  const viewButtonRenderer = (experience) => {
    return (
      experience?.length > 1 && (
        <div style={{ paddingLeft: 32, paddingBottom: "20px" }}>
          {" "}
          <Button
            onClick={toggleExperience}
            type="text"
            className="view-more-btn"
          >
            {checkCardState() ? "View Less" : "View More"}
            <ReactSVG
              src={arrowDown}
              alt="arr-dwn"
              width="24px"
              beforeInjection={(svg) => {
                checkCardState()
                  ? svg.classList.add("svg-rotate")
                  : svg.classList.remove("svg-rotate");
              }}
            />
          </Button>
        </div>
      )
    );
  };
  const ismatchInteresetPref = true;

  return (
    <Card
      className={
        handleClick && selectedJob.id === id
          ? "job-card selected-job "
          : pathname !== "/dashboard"
          ? "job-card "
          : "job-card"
      }
      onClick={() => handleClick && handleCardClick()}
      style={style}
    >
      <div className="card-header">
        <div className="upper-header">
          <div className="header-upper-left">
            <div className="multi-avatar-container">
              <img
                src={isJob ? building : human}
                className="multi-avatar"
                width="40px"
                height="40px"
                alt="multi-icon"
              />
            </div>
            <div>
              <Title
                level={3}
                className={
                  isInterested && !fullName
                    ? "blur text-black font-medium reset-margin card-main-text"
                    : "text-black font-medium reset-margin card-main-text"
                }
              >
                {renderName()}
              </Title>
              <Paragraph className="text-primary card-location d-flex mt-12 mb-0">
                <img src={pin} width="24px" alt="location" className="mr-6p " />
                {jobLocations?.join(", ")}
                {candidateLocationRender()}
              </Paragraph>
            </div>
          </div>

          <div className="header-upper-right">
            {isJob && preview && (
              <div className="d-flex align-center">
                <Switch
                  checked={isChecked}
                  onChange={onChange}
                  className="switch-btn"
                  style={{ marginRight: "15px" }}
                />
                <Typography className="d-flex align-center">
                  Stealth Mode : {isChecked ? "ON" : "OFF"}
                  <Tooltip
                    placement="topLeft"
                    title="If stealth mode is on, this job will be confidential"
                    arrow={false}
                    className="cursor"
                  >
                    <img
                      src={blueInfo}
                      width="16px"
                      alt="info"
                      className="blue-info-icon"
                      style={{ marginLeft: "12px" }}
                    />
                  </Tooltip>
                </Typography>
              </div>
            )}
            {HeaderAction && (
              <HeaderAction
                totalInterestedCandidates={totalInterestedCandidates}
                job={jobDetails}
                jobId={selectedJob?.id}
                candidate={candidate}
                requestId={requestId}
                loading={interestedLoadingId === requestId}
                interestedCandidates={appliedCount}
                matchedCandidates={acceptedCount}
                fullName={fullName}
              />
            )}
          </div>
          {/* <img src={miniLine} className="header-small-border" alt="border" /> */}
        </div>
        {!candidateJob && (
          <img src={line} className="header-small-border" alt="border" />
        )}

        {!candidateJob && (
          <div className="lower-header">
            <Paragraph className="card-lower-header-title">
              {isJob ? "PREFERENCES" : "MATCHED PREFERENCES"}
            </Paragraph>

            <div
              className="d-flex align-center flex-wrap"
              style={{ position: "relative" }}
            >
              {isJob
                ? cardPreferencesRender(
                    candidateJob,
                    preferencesArray,
                    preferences,
                    isJob
                  )
                : cardPreferencesRender(
                    candidateJob,
                    matchedParams,
                    preferences,
                    isJob,
                    ismatchInteresetPref
                  )}
              {/* {isJob && ( */}
              <img
                src={line}
                className="preferences-small-border"
                alt="border"
              />
              {/* )} */}
            </div>
          </div>
        )}
      </div>
      {preview &&
        checkCompanyInfo(jobDescription, companyName) &&
        isChecked && (
          <div
            className="err-info-container preview-job-warning-container"
            id="err-info-container"
          >
            <img src={circleInfo} alt="info" width="16px" />
            <Typography className="check-your-info-text">
              We've found {companyName} in description, which might reveal the
              identity.
            </Typography>
          </div>
        )}
      {/* {!isJob && <img src={line} width="100%" alt="border" />} */}
      {candidateExperiences
        ? cardDescriptionRenderer(
            candidateJob,
            jobDescription,
            isJob,
            candidateExperiences,
            checkCardState,
            viewMore,
            setViewMore,
            jobFunctionName,
            isInterested,
            fullName
          )
        : cardDescriptionRenderer(
            candidateJob,
            jobDescription,
            isJob,
            candidateExperiences,
            checkCardState,
            viewMore,
            setViewMore
          )}
      {viewButtonRenderer(experience)}
      {viewButtonRenderer(candidateExperiences)}
    </Card>
  );
};

export default MultiCard;
