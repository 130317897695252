import React from "react";
import { Outlet } from "react-router-dom";
import "./index.scss";
import { Typography } from "antd";
import silentGirl from "../../Assets/Images/login-avatar.png";
import monoLogo from "../../Assets/Images/hat-round.png";
import Title from "antd/es/typography/Title";

const LoginLayout = () => {
  return (
    <div className="login-container">
      <div className="login-left">
        <div className="logo-round-container">
          <img src={monoLogo} alt="'logo" width="87px" />
        </div>
        <Title level={1} className="login-left-header reset-margin">
          Maintain{" "}
          <span className="text-primary font-bold">Confidentiality</span> As You
          Search For Your Next{" "}
          <span className="text-primary font-bold">Leader</span>
        </Title>
        <div className="login-left-image-container">
          <img src={silentGirl} alt="login-avatar" width="100%" />
          <div className="login-transparent-card-1">
            <Typography className="text-in-glass">
              Discover Your Next Leadership Role
            </Typography>
          </div>
          <div className="login-transparent-card-2">
            <Typography className="text-in-glass">
              Your Gateway to Exclusive Leadership Opportunities
            </Typography>
          </div>
        </div>
      </div>
      <div className="login-right">
        <Outlet />
      </div>
    </div>
  );
};

export default LoginLayout;
