import { Typography } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { navItemHighlighter } from "../../../Utils/helpers";
import { ReactSVG } from "react-svg";

const NavLink = ({ icon, label, link }) => {
  const { pathname } = useLocation();
  return (
    <Link to={link}>
      <div
        className={`${navItemHighlighter(
          label,
          pathname
        )} nav-link-wrapper d-flex align-center`}
      >
        <ReactSVG src={icon} width="28px" />
        {/* <img src={icon} alt="link-icon" width="28px" /> */}
        <Typography className="nav-link-label">{label}</Typography>
      </div>
    </Link>
  );
};

export default NavLink;
