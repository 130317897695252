import React, { useEffect } from "react";
import "./index.scss";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import MultiCard from "../../../Components/MultiCard";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import pencil from "../../../Assets/Icons/pencil.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { createJob } from "../../../Redux/Slices/JobSlice";

const PreviewJob = () => {
  const { jobDetails, updateLoading } = useSelector((state) => state.JobSlice);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const handleEdit = () => navigate(-1);
  const element = document.getElementById("root");

  const elementOffset = element?.getBoundingClientRect().top + window.scrollY;

  const handleCreate = () =>
    dispatch(
      createJob({
        values: {
          ...jobDetails,
          employerId: jobDetails?.employerId?.value,
        },
        toast,
        navigate,
      })
    );
  useEffect(() => {
    window.scrollTo({
      top: elementOffset - 80,
      left: 0,
      behavior: "smooth",
    });
  }, [pathname]);
  return (
    <div className="preview-job-container ">
      <div className="preiview-header">
        <Title level={2} className="preview-heading">
          Preview of your job posting
        </Title>
        <Paragraph className="preview-sub-heading">
          This is how your job will look
        </Paragraph>
      </div>
      <MultiCard {...jobDetails} isJob={true} preview />
      <div className="preview-btm-actions-container">
        <Button
          className="btn-white d-flex align-center preview-btn reg-height-btn"
          icon={<ReactSVG src={pencil} alt="pencil" />}
          onClick={handleEdit}
        >
          Edit Details
        </Button>
        <Button
          type="primary"
          className="post-job-btn preview-btn reg-height-btn"
          onClick={handleCreate}
          disabled={updateLoading}
        >
          Post a New Job{" "}
        </Button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PreviewJob;
