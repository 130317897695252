import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "antd/es/typography/Title";
import Paragraph from "antd/es/typography/Paragraph";
import { Button } from "antd";
import { toast, ToastContainer } from "react-toastify";
import "./index.scss";
import CustomForm from "../../../Components/Form";
import { verifyAccountFields } from "../../../Utils/FormikFieldSkeleton";
import BackContinue from "../../../Components/Form/BackContinue";
import logoText from "../../../Assets/Icons/logo_text.svg";
import { useDispatch, useSelector } from "react-redux";
import { signUp } from "../../../Redux/Slices/AuthSlice";

const VerifyAccount = () => {
  const { signupForm, loading } = useSelector((state) => state.AuthSlice);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const onFinish = (values) => console.log("values", values);
  const onFinish = (values) =>
    dispatch(
      signUp({
        values: {
          ...values,
          ...state,
        },
        navigate,
        toast,
      })
    );

  // const onFinish = (values) => dispatch(signUp({ values, navigate, toast }))navigate("/verify-code", { state: state });
  const initialValues = {
    ...state,
  };
  const btnProps = {
    loading,
  };
  return (
    <div className="verify-container">
      <img src={logoText} width="201px" alt="logo" />
      <Title level={1} className="font-medium text-black">
        We need to Verify your Account
      </Title>
      <Paragraph>
        Enter your company email address, and we'll send you a 4 digit
        verification code for the verification process
      </Paragraph>
      <CustomForm
        formName="verify-account"
        fields={verifyAccountFields}
        onFinish={onFinish}
        style={{ marginTop: "40px" }}
        FormBtn={BackContinue}
        btnProps={btnProps}
        initialValues={initialValues}
      />
      <ToastContainer />
    </div>
  );
};

export default VerifyAccount;
