import { Typography } from "antd";
import {
  updateCandidatePreference,
  updateCandidateRequest,
} from "../Redux/Slices/DashboardSlice";
import { updateJob } from "../Redux/Slices/JobSlice";
import { experienceFields } from "./FormikFieldSkeleton";
import moment from "moment";

export const navItemHighlighter = (label, path) => {
  console.log("path infunction", path);
  switch (label) {
    case "Dashboard":
      if (
        path.includes("/dashboard") &&
        !path.includes("/employers") &&
        !path.includes("/jobs") &&
        !path.includes("/candidates")
      )
        return "active-link";
      break;
    case "Candidates":
      if (
        path.includes("/dashboard") &&
        !path.includes("/employers") &&
        path.includes("/candidates")
      )
        return "active-link";
      break;
    case "Employers":
      if (
        path.includes("/dashboard") &&
        path.includes("/employers") &&
        !path.includes("/candidates")
      )
        return "active-link";
      break;
    case "Manage Jobs":
      if (
        path.includes("/jobs")
        // !path.includes("/candidates")
      )
        return "active-link";
      break;
    default:
      return "";
  }
  return "";
};

export const expireTokenHandler = (message, navigate) => {
  if (message?.includes("expire")) {
    localStorage.clear();
    navigate ? navigate("/") : window.location.reload();
  }
};

export const editFieldRenderer = (selectedDialogTab) => {
  switch (selectedDialogTab) {
    case "experienceLevel":
      return experienceFields;
    case "candidatePreference":
      return experienceFields;

    default:
      return;
  }
};
export const profileSubmitRenderer = (
  selectedDialogTab,
  id,
  dispatch,
  experienceLevelValue,
  toast,
  status
) => {
  switch (selectedDialogTab) {
    case "experienceLevel":
      return (values) =>
        dispatch(
          updateCandidateRequest({
            values: { ...values, experienceLevel: experienceLevelValue },
            requestId: id,
            toast,
          })
        );
    case "candidatePreference":
      return (values) =>
        dispatch(
          updateCandidatePreference({
            values: { ...values, experienceLevel: experienceLevelValue },
            candidateId: id,
            toast,
            status,
            dispatch,
          })
        );

    default:
      return;
  }
};

export const noDataHandler = (value) => (!value ? "No Data" : value);
export const noinudustryTypeHandler = (value) =>
  value?.length === 0
    ? "No Data"
    : value?.map((industryType) => industryType?.name);
export const emptyArrayDataHandler = (value) =>
  value?.length === 0 ? "No Data" : value;

export const currencyFormatter = (value) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export function getMonthAbbreviation(monthNumber) {
  if (monthNumber < 1 || monthNumber > 12) {
    return "Invalid month number";
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return months[monthNumber - 1];
}
export const dateFormatter = (createdAt) => {
  const month = getMonthAbbreviation(moment(createdAt).format("MM"));
  const year = moment(createdAt).format("YYYY");
  const day = moment(createdAt).format("DD");

  return `${day}-${month}-${year}`;
};

export const selectectValueChecker = (id, array) => {
  for (const item of array) {
    if (item.id === id) {
      return item.name;
    }
  }
  return null;
};

export const checkCompanyInfo = (jobDescription, companyName) =>
  jobDescription?.toLowerCase()?.includes(companyName.toLowerCase());

export const currencyParser = (value) => value?.replace(/\$\s?|(,*)/g, "");

export const arrayReducer = (arr) => {
  if (arr.length > 3) {
    return (
      <>
        {arr?.map(({ name }, index) => (
          <>
            {name}
            {index !== arr.length - 1 && (
              <span style={{ marginRight: "4px" }}> / </span>
            )}
          </>
        ))}
        <span className="text-primary "> + {arr.length - 3} more</span>
      </>
    );
  } else
    return arr?.map(({ name }, index) => (
      <>
        {name}
        {index !== arr.length - 1 && (
          <span style={{ marginRight: "4px" }}> / </span>
        )}
      </>
    ));
};
