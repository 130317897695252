import { Button, Form, Modal, Skeleton } from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../DialogHeader";
import userEdit from "../../Assets/Icons/user-pen-alt.svg";
import CustomForm from "../Form";
import { editJobFields, postJobFields } from "../../Utils/FormikFieldSkeleton";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllIndustryTypes,
  getJobById,
  searchEmployers,
  updateJob,
} from "../../Redux/Slices/JobSlice";
import {
  getAllExperienceLevels,
  getAllJobFunctions,
} from "../../Redux/Slices/DashboardSlice";
import { useNavigate } from "react-router-dom";
import { profileSubmitRenderer } from "../../Utils/helpers";
import {
  closeJobDialogue,
  closeJobDrawer,
} from "../../Redux/Slices/DialogSlice";
import { debounce } from "lodash";
import { getAllEmployers } from "../../Redux/Slices/EmployerSlice";

const FormBtn = ({ resetForm, updateLoading, isEditDialog }) => {
  return (
    <div className="d-flex">
      {/* <Button 
        htmlType="submit"
        className="fullWidth"
        disabled={updateLoading}
        size="large"
      >
       Reset
      </Button> */}
      <Button
        type="primary"
        htmlType="submit"
        className="fullWidth reg-height-btn"
        disabled={updateLoading}
        size="large"
      >
        Save Changes
      </Button>
    </div>
  );
};
const EditJobDialog = () => {
  const [form] = Form.useForm();
  const { jobDrawer, editJob } = useSelector((state) => state.DialogSlice);
  const employerSlice = useSelector((state) => state.EmployerSlice);
  const {
    jobDetails,
    jobFunctionLoading,
    experienceLoading,
    industryLoading,
    updateLoading,
    searchLoading,
    loading,
  } = useSelector((state) => state.JobSlice);
  const [locations, setLocations] = useState([]);
  const [jobFunctionArray, setJobFunctionArray] = useState([]);
  const navigate = useNavigate();
  const { id } = jobDrawer?.selectedJobDetails;
  // const { jobDetails?.jobFunctions } = jobDetails;
  const [industryName, setIndustryName] = useState([]);
  const [experienceName, setExperienceName] = useState([]);
  const [jobFunctionName, setJobFunctionName] = useState([]);
  const [experienceLevelValue, setExperienceLevel] = useState("");
  const dispatch = useDispatch();
  const [locationSearchText, setLocationSearchText] = useState("");
  const [employerName, setEmployerName] = useState([]);
  const [searchValue, setSearchValue] = useState(jobDetails?.employerId);
  const industryNameHandler = (data) => {
    const temp = [];
    data.forEach(({ id, name }) => temp.push({ value: id, label: name }));
    setIndustryName(temp);
  };

  const fetchRef = useRef(0);
  const experienceNameHandler = (data) => {
    const temp = [];
    data.forEach(({ id, name }) => temp.push({ value: id, label: name }));
    setExperienceName(temp);
  };
  const jobFunctionNameHandler = (data) => {
    const temp = [];
    data.forEach(({ id, name }) => temp.push({ value: id, label: name }));
    setJobFunctionName(temp);
  };
  // useEffect(() => {
  //   dispatch(getJobById({ id: jobDrawer?.jobDetails?.id }));
  // }, []);
  const employerNameHandler = (data) => {
    const temp = [];
    data.forEach(({ id, user, companyName }) =>
      temp.push({
        value: id,
        label: `${user?.name} (${companyName})`,
        title: companyName,
      })
    );
    setEmployerName(temp);
  };
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      console.log("value", value);
      if (value === "") {
        dispatch(getAllEmployers({ employerNameHandler }));
      } else dispatch(searchEmployers({ employerNameHandler, search: value }));

      // });
    };
    return debounce(loadOptions, 800);
  }, [800]);

  useEffect(() => {
    dispatch(getAllIndustryTypes({ industryNameHandler }));
    dispatch(getAllExperienceLevels({ experienceNameHandler }));
    dispatch(getAllJobFunctions({ jobFunctionNameHandler }));
    dispatch(
      getAllEmployers({
        navigate,
        page: 1,
        employerNameHandler,
        limit: 1000000000,
      })
    );
  }, []);
  const handleJobFunctions = (e, { value, label }) => {
    const existingItem = jobFunctionArray.findIndex(
      (item) => item.value === value
    );
    if (existingItem === -1) {
      setJobFunctionArray([...jobFunctionArray, { value, label }]);
    }
  };
  useEffect(() => {
    jobDetails?.jobLocations?.length > 0 &&
      setLocations(jobDetails?.jobLocations);
    if (jobDetails?.jobFunctions) {
      const temp = jobDetails?.jobFunctions.map((data) => {
        return { value: data.id, label: data.name };
      });
      setJobFunctionArray(temp);
    }
    setExperienceLevel(jobDetails?.experienceLevel);
  }, [jobDetails, loading]);

  const customConfig = [
    { name: "industryTypeId", options: industryName },
    {
      name: "allowedExperienceLevels",
      options: experienceName,
    },
    {
      name: "jobFunctionIds",
      options: jobFunctionName,
    },
    {
      name: "employerId",
      options: searchLoading || loading ? [] : employerName,
    },
  ];
  const handleLocation = (e) => {
    if (!locations.includes(e)) {
      setLocations([...locations, e]);
    }
  };
  const removeLocation = (index) => {
    const temp = [...locations];
    temp.splice(index, 1);
    setLocations(temp);
  };
  const removeJobFunction = (index) => {
    const temp = [...jobFunctionArray];
    temp.splice(index, 1);
    setJobFunctionArray(temp);
  };
  const handleCancel = () => dispatch(closeJobDialogue());
  const closeDrawer = () => dispatch(closeJobDrawer());
  const onFinish = (values, e) => {
    if (values.jobDescription === "<p><br></p>") {
      toast.error("Please enter description");
    } else if (locations.length === 0) {
      toast.error("Please add locations");
      form.setFields([
        {
          name: "jobLocations",
          errors: ["Please use job locations"],
        },
      ]);
    } else if (jobFunctionArray.length === 0) {
      toast.error("Please add job functions");
      form.setFields([
        {
          name: "jobLocations",
          errors: ["Please add job functions"],
        },
      ]);
    } else {
      dispatch(
        updateJob({
          values: {
            ...values,
            employerId: values?.employerId.value
              ? values?.employerId.value
              : values?.employerId,
            jobLocations: locations,
            experienceLevel: experienceLevelValue,
            jobFunctionIds: jobFunctionArray,
          },
          toast,
          id,
          navigate,
          handleCancel,
          closeDrawer,
        })
      );
    }
  };
  const btnProps = { label: "Save Changes", updateLoading };
  const initialValues = {
    ...jobDetails,

    allowedExperienceLevels: jobDetails?.allowedExperienceLevels?.map(
      ({ id }) => id
    ),
  };
  const fieldLoading = () =>
    jobFunctionLoading || experienceLoading || industryLoading || loading;
  return (
    <Modal
      title={<Header icon={userEdit} heading="Edit Job Details" />}
      open={editJob?.open}
      className="profile-modal"
      onCancel={handleCancel}
      width={"866px"}
      footer={null}
      destroyOnClose
      centered
    >
      {fieldLoading() ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <CustomForm
          fields={postJobFields}
          FormBtn={FormBtn}
          btnProps={btnProps}
          customConfig={customConfig}
          initialValues={{ ...initialValues }}
          setExperienceLevel={setExperienceLevel}
          jobFunctionArray={jobFunctionArray}
          handleJobFunctions={handleJobFunctions}
          handleLocation={handleLocation}
          locations={locations}
          removeLocation={removeLocation}
          removeJobFunction={removeJobFunction}
          onFinish={onFinish}
          locationSearchText={locationSearchText}
          setLocationSearchText={setLocationSearchText}
          searchValue={searchValue}
          debounceFetcher={debounceFetcher}
          fetching={searchLoading || loading}
          setSearchValue={setSearchValue}
        />
      )}

      {/* // ) : (
      //   <Skeleton />
      // )} */}
      <ToastContainer />
    </Modal>
  );
};

export default EditJobDialog;
