import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedDialogTab: "",
  selectedDeleteDialogTab: "",
  jobDrawer: {
    open: false,
    selectedJobDetails: {},
  },
  editJob: {
    open: false,
    jobDetails: {},
  },
  preferenceDrawer: false,
  selectedId: "",
  open: false,
  deleteDialog: false,
  fields: {},
};

const DialogSlice = createSlice({
  name: "Dialog",
  initialState,
  reducers: {
    setCandidateType(state, { payload }) {
      state.selectedCandidateTab = payload.candidateType;
    },
    openPreferenceDrawer(state, { payload }) {
      state.preferenceDrawer = true;
      state.selectedId = payload.id;
      // state.fields = payload.fields;
    },
    openJobDrawer(state, { payload }) {
      state.jobDrawer.open = true;
      state.jobDrawer.selectedJobDetails = payload.job;
      // state.fields = payload.fields;
    },
    closeJobDrawer(state) {
      state.jobDrawer.open = false;
      state.jobDrawer.selectedJobDetails = {};
      // state.fields = payload.fields;
    },
    openJobDialogue(state, { payload }) {
      state.editJob.open = true;
      state.editJob.jobDetails = payload.job;
      // state.fields = payload.fields;
    },
    closeJobDialogue(state) {
      state.editJob.open = false;
      state.editJob.jobDetails = {};
      // state.fields = payload.fields;
    },
    closePreferenceDrawer(state, { payload }) {
      state.preferenceDrawer = false;
      state.selectedId = "";
      state.fields = {};
    },
    handleOpenDialog(state, { payload }) {
      console.log("ipayloadd", payload);
      state.selectedId = payload.id;
      state.open = true;
      state.selectedDialogTab = payload.type;
      state.fields = payload.fields;
    },
    handleOpenDeleteDialog(state, { payload }) {
      console.log("ipayloadd", payload);
      state.selectedId = payload.id;
      state.deleteDialog = true;
      state.selectedDeleteDialogTab = payload.type;
      state.fields = payload.fields;
    },
    handleCloseDeleteDialog(state, { payload }) {
      console.log("ipayloadd", payload);
      state.selectedId = "";
      state.deleteDialog = false;
      state.selectedDeleteDialogTab = "";
      state.fields = {};
    },
    handleCloseDialog(state) {
      state.open = false;
      state.selectedId = "";
      state.selectedDialogTab = "";
      state.fields = {};
    },
  },
});

export default DialogSlice.reducer;
export const {
  handleOpenDialog,
  handleOpenDeleteDialog,
  handleCloseDeleteDialog,
  handleCloseDialog,
  openPreferenceDrawer,
  closePreferenceDrawer,
  openJobDrawer,
  closeJobDrawer,
  openJobDialogue,
  closeJobDialogue,
} = DialogSlice.actions;
