import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../Assets/Icons/text_logo_white.svg";
import { Divider } from "antd";
import { navLinks } from "../../../Utils/staticData";
import NavLink from "./NavLink";

const DeskBar = () => {
  return (
    <div className="navbar-container">
      <div className="desk-logo-container">
        {" "}
        <Link to="/dashboard/1">
          <img src={logo} width="200px" alt="logo" />
        </Link>
      </div>
      <Divider style={{ marginTop: 0 }} />
      <div className="nav-link-container">
        {navLinks.map((link) => (
          <NavLink {...link} />
        ))}
      </div>
    </div>
  );
};

export default DeskBar;
