import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, Button, Skeleton, Switch, Typography, Tooltip } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import branch from "branch-sdk";

import MultiCard from "../MultiCard";
import { getJobById, updateJob } from "../../Redux/Slices/JobSlice";
import pencil from "../../Assets/Icons/pencil.svg";
import Facebook from "../../Assets/Icons/fb.svg";
import logo from "../../Assets/Icons/logo_text.svg";
import twitter from "../../Assets/Icons/x.svg";
import linkedIn from "../../Assets/Icons/linkedin.svg";
import link from "../../Assets/Icons/link-grey.svg";
import blueInfo from "../../Assets/Icons/circle-information-blue.svg";
import {
  closeJobDrawer,
  openJobDialogue,
} from "../../Redux/Slices/DialogSlice";
import "./index.scss";
import EditJobDialog from "../EditJobDialog";
import { useNavigate } from "react-router-dom";

const HeaderAction = ({ job }) => {
  const { updateLoading } = useSelector((state) => state.JobSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const baseUrl =
    process.env.REACT_APP_SHARE_JOB_URL + "/shared-job/" + job.slug;
  const handleCopy = () => {
    toast.success("Link copied to clipboard");
    navigator.clipboard.writeText(baseUrl);
  };
  const [isChecked, setChecked] = useState(job?.stealthMode);
  const handleChecked = (e) => setChecked(e);
  const closeDrawer = () => dispatch(closeJobDrawer());
  const onChange = (e) => {
    setChecked(e);
    dispatch(
      updateJob({
        values: { stealthMode: e },
        handleChecked,
        e,
        toast,
        closeDrawer,
        id: job?.id,
        navigate,
      })
    );
  };
  useEffect(() => {
    setChecked(job?.stealthMode);
  }, [job]);
  const [linkToSend, setLinkToSend] = useState("");
  const regex = /<\/?[^>]+(>|$)/g;
  const filteredJd = job?.jobDescription?.replace(regex, "");
  useEffect(() => {
    const options = {
      no_journeys: true,
    };
    branch.init(
      "key_live_gufU8Fys9JhdObmTcuyIldkcuyf5TAjh",
      options,
      function (err, data) {
        console.log("branchio", err, data);
      }
    );

    var linkData = {
      // initial_referrer: "https://www.google.com/",
      source: "web-sdk",
      // metadata: {
      //   og_data: {
      //     $og_title: "Branch Metrics Web SDK Example App",
      //     $og_description: "A basic example of the Branch Web SDK.",
      //   },
      // },
      // campaign: "content 123",
      // channel: "facebook",
      // feature: "dashboard",
      // stage: "new user",
      // tags: ["tag1", "tag2", "tag3"],
      // alias: "",
      data: {
        $canonical_url: baseUrl,
        $desktop_url: baseUrl,
        link_type: "QUICK_LINK",
        $web_only: true,
        custom_bool: true,
        custom_int: Date.now(),
        custom_string: "hello",
        $og_title: job.jobTitle,
        $og_description: filteredJd,
        // $og_image_url:
        //   "https://devcandidate.stealthjobs.co/static/media/logo_text.052f8fac6afcb0ee824f462f669aa3f2.svg",
      },
    };
    branch.link(linkData, function (err, link) {
      setLinkToSend(link);
      console.log("branch link", link);
    });
  }, []);

  return (
    <div>
      <div className="view-job-header">
        <a
          // href={linkToSend}
          href={`http://www.facebook.com/sharer/sharer.php?u=${baseUrl}&t=${filteredJd}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            icon={
              <img src={Facebook} width="24px" height="24px" alt="social" />
            }
            size="large"
            className="d-flex justify-center btn-14 job-table-action"
          />
        </a>
        <a
          // href={linkToSend}
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${linkToSend}&text=${filteredJd}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            icon={
              <img src={linkedIn} width="24px" height="24px" alt="social" />
            }
            size="large"
            className="d-flex justify-center btn-14 job-table-action"
          />
        </a>
        {/* <Insta /> */}
        <a
          href={`http://www.x.com/intent/tweet?url=${baseUrl}&text=${filteredJd}`}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            icon={<img src={twitter} width="24px" height="24px" alt="social" />}
            size="large"
            className="d-flex justify-center btn-14 job-table-action"
          />
        </a>
        <Button
          icon={<img src={link} width="24px" height="24px" alt="social" />}
          size="large"
          className="d-flex justify-center align-center btn-16 font-medium job-table-action"
          onClick={handleCopy}
        >
          Copy job link
        </Button>

        {/* <Button
        className="multi-action-btn d-flex align-center"
        // onClick={() => handleAction("interested", job)}
        icon={
          <img
            src={send}
            alt="interest-btn"
            className="interested-btn-img"
            width={24}
          />
        }
        size="large"
      >
        Share job
      </Button> */}
        <Button
          className="multi-action-btn d-flex align-center btn-16 font-medium"
          onClick={() => dispatch(openJobDialogue({ job }))}
          icon={
            <img
              src={pencil}
              alt="interest-btn"
              className="interested-btn-img"
              width={24}
            />
          }
          size="large"
        >
          Edit job
        </Button>
      </div>
      <div className=" view-job-switch-container">
        <Switch
          defaultChecked={isChecked}
          checked={isChecked}
          onChange={onChange}
          className="switch-btn"
          style={{ marginRight: "15px" }}
          disabled={updateLoading}
        />
        <Typography className="d-flex align-center">
          Stealth Mode : {isChecked ? "ON" : "OFF"}
          <Tooltip
            placement="topLeft"
            title="If stealth mode is on, company name will be confidential"
            arrow={false}
            className="cursor"
          >
            <img
              src={blueInfo}
              width="16px"
              alt="info"
              className="blue-info-icon"
              style={{ marginLeft: "12px" }}
            />
          </Tooltip>
        </Typography>
      </div>
    </div>
  );
};
const JobDrawer = () => {
  const { jobDrawer, editJob } = useSelector((state) => state.DialogSlice);
  const { jobDetails, getJobsLoading } = useSelector((state) => state.JobSlice);
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeJobDrawer());
  useEffect(() => {
    jobDrawer?.selectedJobDetails?.id &&
      dispatch(getJobById({ id: jobDrawer?.selectedJobDetails?.id }));
  }, [jobDrawer.open]);

  return (
    <Drawer onClose={onClose} open={jobDrawer.open} width="70vw">
      {getJobsLoading ? (
        <Skeleton />
      ) : (
        <MultiCard {...jobDetails} isJob={true} HeaderAction={HeaderAction} />
      )}
      <EditJobDialog />

      {/* <ToastContainer /> */}
    </Drawer>
  );
};

export default JobDrawer;
