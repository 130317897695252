import { configureStore } from "@reduxjs/toolkit";
import JobSlice from "./Slices/JobSlice";
import CandidateSlice from "./Slices/CandidateSlice";
import AuthSlice from "./Slices/AuthSlice";
import DashboardSlice from "./Slices/DashboardSlice";
import EmployerSlice from "./Slices/EmployerSlice";
import DialogSlice from "./Slices/DialogSlice";

const Store = configureStore({
  reducer: {
    AuthSlice,
    DashboardSlice,
    JobSlice,
    CandidateSlice,
    EmployerSlice,
    DialogSlice,
  },
});

export default Store;
