import React, { useEffect, useState } from "react";
import CustomTable from "../../Components/Table";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  allApprovedJobsColumns,
  allPendingJobsColumns,
  allRejectedJobsColumns,
} from "../../Utils/tableData";
import { getAllJobs } from "../../Redux/Slices/JobSlice";
import JobDrawer from "../../Components/JobDrawer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Jobs = () => {
  const { editJob } = useSelector((state) => state.DialogSlice);
  const { jobs, loading, updateLoading, total } = useSelector(
    (state) => state.JobSlice
  );
  const localStatus = localStorage.getItem("jobsListStatus");
  const [status, setStatus] = useState(localStatus ? localStatus : "approved");
  const [limit, setLimit] = useState(10);
  // const[page,setPage]=useState(1)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pageNumber } = useParams();

  const pagination = {
    total,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    current: pageNumber,
    pageSize: limit,
    onChange: (newPage) => {
      localStorage.setItem("jobsPage", newPage);
      // setPage(newPage);
      navigate(`/dashboard/jobs/${newPage}`);
    },
    onShowSizeChange: (e, value) => setLimit(value),
  };
  const handleStatus = (e) => {
    // setPage(1);
    setStatus(e.target.value);
    localStorage.setItem("jobsListStatus", e.target.value);
    navigate(`/dashboard/jobs/1`);
  };
  useEffect(() => {
    dispatch(getAllJobs({ status, page: pageNumber, limit }));
  }, [status, editJob?.open, pageNumber]);
  const columnsToRender = () => {
    switch (status) {
      case "approved":
        return allApprovedJobsColumns;
      case "pending":
        return allPendingJobsColumns;
      case "rejected":
        return allRejectedJobsColumns;
      default:
        return allApprovedJobsColumns;
    }
  };
  return (
    <div className="jobs-container">
      <div className="job-container-header">
        <CustomTable
          title="Manage jobs"
          dataSource={jobs}
          columns={columnsToRender()}
          loading={loading || updateLoading}
          renderTabsJobs
          status={status}
          handleStatus={handleStatus}
          pagination={pagination}
          isJob
        />
        <JobDrawer />
      </div>
      <ToastContainer />
    </div>
  );
};

export default Jobs;
