export const loginFieldData = [
  {
    id: 1,
    name: "workEmail",
    label: "Email Address",
    rules: [
      {
        required: true,
        message: "Please enter work email",
      },
      {
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

        message: "Enter valid email only",
      },
    ],
    placeholder: "Enter email address",
    type: "text",
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 2,
    name: "password",
    label: "Password",
    rules: [
      {
        required: true,
        message: "please enter password",
      },
      // {
      //   message:
      //     "Password must contain atleast 8 characters, one upper case, a lower case and a special character",
      //   pattern:
      //     "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      // },
    ],
    placeholder: "Enter password",
    type: "password.linked",
    size: {
      sm: 24,
      md: 24,
    },
  },
];

export const forgotFields = [
  {
    id: 1,
    name: "workEmail",
    label: "Email Address",
    rules: [
      {
        required: true,
        message: "Please enter work email",
      },
      {
        pattern:
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,

        message: "Enter valid email only",
      },
    ],
    placeholder: "Enter email address",
    type: "text",
    size: {
      sm: 24,
      md: 24,
    },
  },
];

export const experienceFields = [
  {
    id: 1,
    name: "jobFunctionId",
    label: "Primary Job Function",
    rules: [
      // {
      //   required: true,
      //   message: "Please select job function",
      // },
    ],
    placeholder: "Select primary job function",
    type: "select",
    options: [],
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 2,
    name: "experienceLevel",
    label: "Experience Level",
    rules: [
      {
        required: true,
        message: "Please select experience level",
      },
    ],
    placeholder: "Select experience level",
    type: "select",
    options: [],
    size: {
      sm: 24,
      md: 24,
    },
  },
];

export const verifyAccountFields = [
  {
    id: 1,
    name: "workEmail",
    label: "Company Email Address",
    rules: [],
    placeholder: "",
    type: "text",
    isDisabled: true,
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 2,
    name: "linkedInProfile",
    label: "LinkedIn Profile",
    rules: [
      {
        required: true,
        message: "Please enter LinkedIn profile url",
      },
    ],
    placeholder: "Enter profile url",
    type: "text",
    size: {
      sm: 24,
      md: 24,
    },
  },
];
export const verificationCodeFields = [
  {
    id: 1,
    name: "code",
    label: "Enter your 4 Digit code",
    rules: [
      {
        required: true,
        message: "Please enter your 4 digit code",
      },
    ],
    placeholder: "Enter 4 digit code",
    type: "text",
    size: {
      sm: 24,
      md: 24,
    },
  },
];
export const resetPasswordFields = [
  {
    id: 1,
    name: "password",
    label: "Create Password",
    rules: [
      {
        required: true,
        message: "please enter password",
      },
      {
        message:
          "Password must contain atleast 8 characters, one upper case, a lower case and a special character",
        pattern:
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      },
    ],
    placeholder: "Create password",
    type: "password",
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 2,
    name: "confirmPassword",
    label: "Confirm Password",
    rules: [
      {
        required: true,
        message: "please enter password",
      },
      (formInstance) => ({
        message: "Confirm password and password must be same",
        validator(rule, value) {
          if (value === null) {
            return Promise.resolve();
          }
          const password = formInstance.getFieldValue("password");
          if (value !== password) {
            return Promise.reject(new Error());
          }
          return Promise.resolve();
        },
      }),
      {
        message:
          "Password must contain atleast 8 characters, one upper case, a lower case and a special character",
        pattern:
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
      },
    ],
    placeholder: "Confirm your password",
    type: "password",
    size: {
      sm: 24,
      md: 24,
    },
  },
];

export const postJobFields = [
  {
    id: 1,
    name: "employerId",
    label: "Employer",
    rules: [
      {
        required: true,
        message: "Please add employer",
      },
    ],
    placeholder: "Select Employer",
    type: "search",

    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 1.1,
    name: "companyName",
    label: "Company name",
    rules: [
      {
        required: true,
        message: "Please add company Name",
      },
    ],
    placeholder: "Enter company name",
    type: "text",
    helperText:
      "This information will be visible only after the candidate has matched with job",
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 2,
    name: "industryTypeId",
    label: "What industry is this company?",
    rules: [
      {
        required: true,
        message: "Please select industry",
      },
    ],
    placeholder: "Select industry",
    type: "select",
    options: [],
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 3,
    name: "jobFunctionIds",
    label: "Primary job function",
    rules: [
      // {
      //   required: true,
      //   message: "Please select job function",
      // },
    ],
    placeholder: "Select primary job function",
    type: "select",
    options: [],
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 4,
    name: "jobTitle",
    label: "Job title",
    rules: [
      {
        required: true,
        message: "Please enter job title",
      },
    ],
    placeholder: "Enter job title",
    type: "text",
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 5,
    name: "allowedExperienceLevels",
    label: "Current experience level of candidate",
    rules: [
      {
        required: true,
        message: "Please select at least 1 experience level",
      },
    ],
    tooltip:
      "Only candidates that have this experience level can view this job posting",
    placeholder: "Select experience level",
    type: "checkbox",
    options: [],
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 12,
    },
  },
  {
    id: 6,
    name: "jobLocations",
    label: "Job location",
    rules: [
      {
        required: true,
        message: "Please enter job location",
      },
    ],
    placeholder: "eg: New York",
    type: "autocomplete",
    options: [
      { value: "Akron", label: "Akron" },
      { value: "Albuquerque", label: "Albuquerque" },
      { value: "Anchorage", label: "Anchorage" },
      { value: "Anaheim", label: "Anaheim" },
      { value: "Arlington", label: "Arlington" },
      { value: "Atlanta", label: "Atlanta" },
      { value: "Austin", label: "Austin" },
      { value: "Bakersfield", label: "Bakersfield" },
      { value: "Baltimore", label: "Baltimore" },
      { value: "Baton Rouge", label: "Baton Rouge" },
      { value: "Birmingham", label: "Birmingham" },
      { value: "Boise", label: "Boise" },
      { value: "Boston", label: "Boston" },
      { value: "Buffalo", label: "Buffalo" },
      { value: "Charlotte", label: "Charlotte" },
      { value: "Chandler", label: "Chandler" },
      { value: "Chesapeake", label: "Chesapeake" },
      { value: "Chicago", label: "Chicago" },
      { value: "Chula Vista", label: "Chula Vista" },
      { value: "Cincinnati", label: "Cincinnati" },
      { value: "Cleveland", label: "Cleveland" },
      { value: "Colorado Springs", label: "Colorado Springs" },
      { value: "Columbus", label: "Columbus" },
      { value: "Corpus Christi", label: "Corpus Christi" },
      { value: "Dallas", label: "Dallas" },
      { value: "Denver", label: "Denver" },
      { value: "Des Moines", label: "Des Moines" },
      { value: "Detroit", label: "Detroit" },
      { value: "Durham", label: "Durham" },
      { value: "El Paso", label: "El Paso" },
      { value: "Fort Wayne", label: "Fort Wayne" },
      { value: "Fort Worth", label: "Fort Worth" },
      { value: "Fayetteville", label: "Fayetteville" },
      { value: "Fontana", label: "Fontana" },
      { value: "Fremont", label: "Fremont" },
      { value: "Fresno", label: "Fresno" },
      { value: "Frisco", label: "Frisco" },
      { value: "Gilbert", label: "Gilbert" },
      { value: "Glendale", label: "Glendale" },
      { value: "Grand Rapids", label: "Grand Rapids" },
      { value: "Greensboro", label: "Greensboro" },
      { value: "Hialeah", label: "Hialeah" },
      { value: "Honolulu", label: "Honolulu" },
      { value: "Houston", label: "Houston" },
      { value: "Huntington Beach", label: "Huntington Beach" },
      { value: "Huntsville", label: "Huntsville" },
      { value: "Indianapolis", label: "Indianapolis" },
      { value: "Irvine", label: "Irvine" },
      { value: "Irving", label: "Irving" },
      { value: "Jacksonville", label: "Jacksonville" },
      { value: "Jersey City", label: "Jersey City" },
      { value: "Kansas City", label: "Kansas City" },
      { value: "Laredo", label: "Laredo" },
      { value: "Las Vegas", label: "Las Vegas" },
      { value: "Lexington", label: "Lexington" },
      { value: "Lincoln", label: "Lincoln" },
      { value: "Long Beach", label: "Long Beach" },
      { value: "Los Angeles", label: "Los Angeles" },
      { value: "Louisville", label: "Louisville" },
      { value: "Lubbock", label: "Lubbock" },
      { value: "Madison", label: "Madison" },
      { value: "McKinney", label: "McKinney" },
      { value: "Memphis", label: "Memphis" },
      { value: "Mesa", label: "Mesa" },
      { value: "Miami", label: "Miami" },
      { value: "Milwaukee", label: "Milwaukee" },
      { value: "Minneapolis", label: "Minneapolis" },
      { value: "Modesto", label: "Modesto" },
      { value: "Montgomery", label: "Montgomery" },
      { value: "Moreno Valley", label: "Moreno Valley" },
      { value: "Nashville", label: "Nashville" },
      { value: "New Jersey", label: "New Jersey" },
      { value: "New Orleans", label: "New Orleans" },
      { value: "New York", label: "New York" },
      { value: "Newark", label: "Newark" },
      { value: "Norfolk", label: "Norfolk" },
      { value: "North Las Vegas", label: "North Las Vegas" },
      { value: "Oakland", label: "Oakland" },
      { value: "Oklahoma City", label: "Oklahoma City" },
      { value: "Omaha", label: "Omaha" },
      { value: "Orlando", label: "Orlando" },
      { value: "Oxnard", label: "Oxnard" },
      { value: "Philadelphia", label: "Philadelphia" },
      { value: "Phoenix", label: "Phoenix" },
      { value: "Pittsburgh", label: "Pittsburgh" },
      { value: "Plano", label: "Plano" },
      { value: "Port St. Lucie", label: "Port St. Lucie" },
      { value: "Portland", label: "Portland" },
      { value: "Raleigh", label: "Raleigh" },
      { value: "Remote", label: "Remote" },
      { value: "Reno", label: "Reno" },
      { value: "Richmond", label: "Richmond" },
      { value: "Riverside", label: "Riverside" },
      { value: "Rochester", label: "Rochester" },
      { value: "Sacramento", label: "Sacramento" },
      { value: "Salt Lake City", label: "Salt Lake City" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Bernardino", label: "San Bernardino" },
      { value: "San Diego", label: "San Diego" },
      { value: "San Francisco", label: "San Francisco" },
      { value: "San Jose", label: "San Jose" },
      { value: "Santa Ana", label: "Santa Ana" },
      { value: "Santa Clarita", label: "Santa Clarita" },
      { value: "Scottsdale", label: "Scottsdale" },
      { value: "Seattle", label: "Seattle" },
      { value: "Spokane", label: "Spokane" },
      { value: "St. Louis", label: "St. Louis" },
      { value: "St. Paul", label: "St. Paul" },
      { value: "St. Petersburg", label: "St. Petersburg" },
      { value: "Stockton", label: "Stockton" },
      { value: "Tacoma", label: "Tacoma" },
      { value: "Tampa", label: "Tampa" },
      { value: "Toledo", label: "Toledo" },
      { value: "Tucson", label: "Tucson" },
      { value: "Tulsa", label: "Tulsa" },
      { value: "Virginia Beach", label: "Virginia Beach" },
      { value: "Washington", label: "Washington" },
      { value: "Wichita", label: "Wichita" },
      { value: "Winston-Salem", label: "Winston-Salem" },
      { value: "Yonkers", label: "Yonkers" },
    ],

    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 7,
    name: "locationType",
    label: "Location type",
    rules: [
      {
        required: true,
        message: "Please select location type",
      },
    ],
    type: "checkbox",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "Remote",
        value: "Remote",
      },
      {
        label: "In Office",
        value: "In Office",
      },
      {
        label: "Hybrid",
        value: "Hybrid",
      },
    ],
  },
  {
    id: 8,
    name: "employmentType",
    label: "Employment type",
    rules: [
      {
        required: true,
        message: "Please select employement type",
      },
    ],
    type: "checkbox",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "Full Time",
        value: "Full Time",
      },
      {
        label: "Part Time",
        value: "Part Time",
      },
      {
        label: "Contract",
        value: "Contract",
      },
    ],
  },
  {
    id: 9,
    name: "minSalary",
    label: "Salary range",
    rules: [
      {
        message: "Please enter numeric value",
        pattern: /^-?\d*(\.\d*)?$/,
      },
      (formInstance) => ({
        message: "Min salary must be less than max salary",
        validator(rule, value) {
          const maxSalary = formInstance.getFieldValue("maxSalary");
          if (value == null && maxSalary == null) {
            return Promise.resolve();
          }
          if (Number(maxSalary) <= Number(value)) {
            return Promise.reject(new Error());
          } else {
            return Promise.resolve();
          }
        },
      }),
    ],
    placeholder: "",
    type: "text.min",
    size: {
      sm: 24,
      md: 12,
    },
  },
  {
    id: 10,
    name: "maxSalary",
    label: " ",
    rules: [
      {
        message: "Please enter numeric value",
        pattern: /^-?\d*(\.\d*)?$/,
      },
      (formInstance) => ({
        message: "Max salary is mandatory and must be more than min salary",
        validator(rule, value) {
          const minSalary = formInstance.getFieldValue("minSalary");
          console.log("Min Salary", minSalary, value);
          if (value == null && minSalary == null) {
            return Promise.resolve();
          }
          if (Number(minSalary) >= Number(value)) {
            return Promise.reject(new Error());
          } else {
            return Promise.resolve();
          }
        },
      }),
    ],
    placeholder: "",
    type: "text.max",
    size: {
      sm: 24,
      md: 12,
    },
  },
  {
    id: 11,
    name: "companyArr",
    label: "Company ARR",
    rules: [],
    type: "radio",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "$0M-$10M",
        value: "$0M-$10M",
      },
      {
        label: "$10M-$50M",
        value: "$10M-$50M",
      },
      {
        label: "$50M-$100M",
        value: "$50M-$100M",
      },
      {
        label: "$100M-$250M",
        value: "$100M-$250M",
      },
      {
        label: "$250M-$500M",
        value: "$250M-$500M",
      },
      {
        label: "$500M+",
        value: "$500M+",
      },
    ],
  },
  {
    id: 12,
    name: "numberOfEmployees",

    label: "Number of employees in the company",
    rules: [],

    type: "radio",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "1-10",
        value: "1-10",
      },
      {
        label: "11-50",
        value: "11-50",
      },
      {
        label: "51-200",
        value: "51-200",
      },
      {
        label: "201-500",
        value: "201-500",
      },
      {
        label: "501-1000",
        value: "501-1000",
      },
      {
        label: "1001+",
        value: "1001+",
      },
    ],
  },
  {
    id: 13,
    name: "companyStage",

    label: "Company stage",
    rules: [],
    type: "radio",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "Seed",
        value: "Seed",
      },
      {
        label: "Series A",
        value: "Series A",
      },
      {
        label: "Series B",
        value: "Series B",
      },
      {
        label: "Series C",
        value: "Series C",
      },
      {
        label: "Series D",
        value: "Series D",
      },
      {
        label: "Series E+",
        value: "Series E+",
      },
    ],
  },
  {
    id: 14,
    name: "jobDescription",
    label: "Job description",
    rules: [
      {
        required: true,
        message: "Please enter job description",
      },
    ],
    placeholder: "Enter job description",
    type: "description",
    size: {
      sm: 24,
      md: 24,
    },
  },
];
export const editJobFields = [
  {
    id: 1,
    name: "companyName",
    label: "Company name",
    rules: [
      {
        required: true,
        message: "Please add employer",
      },
    ],
    placeholder: "Select Employer",
    type: "text",
    helperText:
      "This information will be visible only after the candidate has matched with job",
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 2,
    name: "industryTypeId",
    label: "What industry is this company?",
    rules: [
      {
        required: true,
        message: "Please select industry",
      },
    ],
    placeholder: "Select industry",
    type: "select",
    options: [],
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 3,
    name: "jobFunctionId",
    label: "Primary job function",
    rules: [
      // {
      //   required: true,
      //   message: "Please select job function",
      // },
    ],
    placeholder: "Select primary job function",
    type: "select",
    options: [],
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 4,
    name: "jobTitle",
    label: "Job title",
    rules: [
      {
        required: true,
        message: "Please enter job title",
      },
    ],
    placeholder: "Enter job title",
    type: "text",
    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 5,
    name: "allowedExperienceLevels",
    label: "Current experience level of candidate",
    rules: [
      {
        required: true,
        message: "Please select at least 1 experience level",
      },
    ],
    tooltip:
      "Only candidates that have this experience level can view this job posting",
    placeholder: "Select experience level",
    type: "checkbox",
    options: [],
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 12,
    },
  },
  {
    id: 6,
    name: "jobLocations",
    label: "Job location",
    rules: [
      {
        required: true,
        message: "Please enter job location",
      },
    ],
    placeholder: "eg: New York",
    type: "autocomplete",
    options: [
      { value: "Akron", label: "Akron" },
      { value: "Albuquerque", label: "Albuquerque" },
      { value: "Anchorage", label: "Anchorage" },
      { value: "Anaheim", label: "Anaheim" },
      { value: "Arlington", label: "Arlington" },
      { value: "Atlanta", label: "Atlanta" },
      { value: "Austin", label: "Austin" },
      { value: "Bakersfield", label: "Bakersfield" },
      { value: "Baltimore", label: "Baltimore" },
      { value: "Baton Rouge", label: "Baton Rouge" },
      { value: "Birmingham", label: "Birmingham" },
      { value: "Boise", label: "Boise" },
      { value: "Boston", label: "Boston" },
      { value: "Buffalo", label: "Buffalo" },
      { value: "Charlotte", label: "Charlotte" },
      { value: "Chandler", label: "Chandler" },
      { value: "Chesapeake", label: "Chesapeake" },
      { value: "Chicago", label: "Chicago" },
      { value: "Chula Vista", label: "Chula Vista" },
      { value: "Cincinnati", label: "Cincinnati" },
      { value: "Cleveland", label: "Cleveland" },
      { value: "Colorado Springs", label: "Colorado Springs" },
      { value: "Columbus", label: "Columbus" },
      { value: "Corpus Christi", label: "Corpus Christi" },
      { value: "Dallas", label: "Dallas" },
      { value: "Denver", label: "Denver" },
      { value: "Des Moines", label: "Des Moines" },
      { value: "Detroit", label: "Detroit" },
      { value: "Durham", label: "Durham" },
      { value: "El Paso", label: "El Paso" },
      { value: "Fort Wayne", label: "Fort Wayne" },
      { value: "Fort Worth", label: "Fort Worth" },
      { value: "Fayetteville", label: "Fayetteville" },
      { value: "Fontana", label: "Fontana" },
      { value: "Fremont", label: "Fremont" },
      { value: "Fresno", label: "Fresno" },
      { value: "Frisco", label: "Frisco" },
      { value: "Gilbert", label: "Gilbert" },
      { value: "Glendale", label: "Glendale" },
      { value: "Grand Rapids", label: "Grand Rapids" },
      { value: "Greensboro", label: "Greensboro" },
      { value: "Hialeah", label: "Hialeah" },
      { value: "Honolulu", label: "Honolulu" },
      { value: "Houston", label: "Houston" },
      { value: "Huntington Beach", label: "Huntington Beach" },
      { value: "Huntsville", label: "Huntsville" },
      { value: "Indianapolis", label: "Indianapolis" },
      { value: "Irvine", label: "Irvine" },
      { value: "Irving", label: "Irving" },
      { value: "Jacksonville", label: "Jacksonville" },
      { value: "Jersey City", label: "Jersey City" },
      { value: "Kansas City", label: "Kansas City" },
      { value: "Laredo", label: "Laredo" },
      { value: "Las Vegas", label: "Las Vegas" },
      { value: "Lexington", label: "Lexington" },
      { value: "Lincoln", label: "Lincoln" },
      { value: "Long Beach", label: "Long Beach" },
      { value: "Los Angeles", label: "Los Angeles" },
      { value: "Louisville", label: "Louisville" },
      { value: "Lubbock", label: "Lubbock" },
      { value: "Madison", label: "Madison" },
      { value: "McKinney", label: "McKinney" },
      { value: "Memphis", label: "Memphis" },
      { value: "Mesa", label: "Mesa" },
      { value: "Miami", label: "Miami" },
      { value: "Milwaukee", label: "Milwaukee" },
      { value: "Minneapolis", label: "Minneapolis" },
      { value: "Modesto", label: "Modesto" },
      { value: "Montgomery", label: "Montgomery" },
      { value: "Moreno Valley", label: "Moreno Valley" },
      { value: "Nashville", label: "Nashville" },
      { value: "New Jersey", label: "New Jersey" },
      { value: "New Orleans", label: "New Orleans" },
      { value: "New York", label: "New York" },
      { value: "Newark", label: "Newark" },
      { value: "Norfolk", label: "Norfolk" },
      { value: "North Las Vegas", label: "North Las Vegas" },
      { value: "Oakland", label: "Oakland" },
      { value: "Oklahoma City", label: "Oklahoma City" },
      { value: "Omaha", label: "Omaha" },
      { value: "Orlando", label: "Orlando" },
      { value: "Oxnard", label: "Oxnard" },
      { value: "Philadelphia", label: "Philadelphia" },
      { value: "Phoenix", label: "Phoenix" },
      { value: "Pittsburgh", label: "Pittsburgh" },
      { value: "Plano", label: "Plano" },
      { value: "Port St. Lucie", label: "Port St. Lucie" },
      { value: "Portland", label: "Portland" },
      { value: "Raleigh", label: "Raleigh" },
      { value: "Remote", label: "Remote" },
      { value: "Reno", label: "Reno" },
      { value: "Richmond", label: "Richmond" },
      { value: "Riverside", label: "Riverside" },
      { value: "Rochester", label: "Rochester" },
      { value: "Sacramento", label: "Sacramento" },
      { value: "Salt Lake City", label: "Salt Lake City" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Bernardino", label: "San Bernardino" },
      { value: "San Diego", label: "San Diego" },
      { value: "San Francisco", label: "San Francisco" },
      { value: "San Jose", label: "San Jose" },
      { value: "Santa Ana", label: "Santa Ana" },
      { value: "Santa Clarita", label: "Santa Clarita" },
      { value: "Scottsdale", label: "Scottsdale" },
      { value: "Seattle", label: "Seattle" },
      { value: "Spokane", label: "Spokane" },
      { value: "St. Louis", label: "St. Louis" },
      { value: "St. Paul", label: "St. Paul" },
      { value: "St. Petersburg", label: "St. Petersburg" },
      { value: "Stockton", label: "Stockton" },
      { value: "Tacoma", label: "Tacoma" },
      { value: "Tampa", label: "Tampa" },
      { value: "Toledo", label: "Toledo" },
      { value: "Tucson", label: "Tucson" },
      { value: "Tulsa", label: "Tulsa" },
      { value: "Virginia Beach", label: "Virginia Beach" },
      { value: "Washington", label: "Washington" },
      { value: "Wichita", label: "Wichita" },
      { value: "Winston-Salem", label: "Winston-Salem" },
      { value: "Yonkers", label: "Yonkers" },
    ],

    size: {
      sm: 24,
      md: 24,
    },
  },
  {
    id: 7,
    name: "locationType",
    label: "Location type",
    rules: [
      {
        required: true,
        message: "Please select location type",
      },
    ],
    type: "checkbox",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "Remote",
        value: "Remote",
      },
      {
        label: "In Office",
        value: "In Office",
      },
      {
        label: "Hybrid",
        value: "Hybrid",
      },
    ],
  },
  {
    id: 8,
    name: "employmentType",
    label: "Employment type",
    rules: [
      {
        required: true,
        message: "Please select employement type",
      },
    ],
    type: "checkbox",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "Full Time",
        value: "Full Time",
      },
      {
        label: "Part Time",
        value: "Part Time",
      },
      {
        label: "Contract",
        value: "Contract",
      },
    ],
  },
  {
    id: 9,
    name: "minSalary",
    label: "Salary range",
    rules: [
      {
        required: true,
        message: "Please enter salary range",
      },
      {
        message: "Please enter numeric value",
        pattern: /^-?\d*(\.\d*)?$/,
      },
      (formInstance) => ({
        message: "Min salary must be less than max salary",
        validator(rule, value) {
          const maxSalary = formInstance.getFieldValue("maxSalary");
          if (Number(maxSalary) <= Number(value)) {
            return Promise.reject(new Error());
          } else {
            return Promise.resolve();
          }
        },
      }),
    ],
    placeholder: "",
    type: "text.min",
    size: {
      sm: 24,
      md: 12,
    },
  },
  {
    id: 10,
    name: "maxSalary",
    label: " ",
    rules: [
      // {
      //   required: true,
      //   message: "Please enter salary range",
      // },
      {
        message: "Please enter numeric value",
        pattern: /^-?\d*(\.\d*)?$/,
      },
      (formInstance) => ({
        message: "Max salary is mandatory and must be more than min salary",
        validator(rule, value) {
          const minSalary = formInstance.getFieldValue("minSalary");
          if (Number(minSalary) >= Number(value)) {
            return Promise.reject(new Error());
          } else if (!value) {
            return Promise.reject(new Error());
          } else {
            return Promise.resolve();
          }
        },
      }),
    ],
    placeholder: "",
    type: "text.max",
    size: {
      sm: 24,
      md: 12,
    },
  },
  {
    id: 11,
    name: "companyArr",
    label: "Company ARR",
    rules: [],
    type: "radio",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "$0M-$10M",
        value: "$0M-$10M",
      },
      {
        label: "$10M-$50M",
        value: "$10M-$50M",
      },
      {
        label: "$50M-$100M",
        value: "$50M-$100M",
      },
      {
        label: "$100M-$250M",
        value: "$100M-$250M",
      },
      {
        label: "$250M-$500M",
        value: "$250M-$500M",
      },
      {
        label: "$500M+",
        value: "$500M+",
      },
    ],
  },
  {
    id: 12,
    name: "numberOfEmployees",

    label: "Number of employees in the company",
    rules: [],

    type: "radio",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "1-10",
        value: "1-10",
      },
      {
        label: "11-50",
        value: "11-50",
      },
      {
        label: "51-200",
        value: "51-200",
      },
      {
        label: "201-500",
        value: "201-500",
      },
      {
        label: "501-1000",
        value: "501-1000",
      },
      {
        label: "1001+",
        value: "1001+",
      },
    ],
  },
  {
    id: 13,
    name: "companyStage",

    label: "Company stage",
    rules: [],
    type: "radio",
    size: {
      sm: 24,
      md: 24,
    },
    boxSize: {
      sm: 24,
      md: 8,
    },
    options: [
      {
        label: "Seed",
        value: "Seed",
      },
      {
        label: "Series A",
        value: "Series A",
      },
      {
        label: "Series B",
        value: "Series B",
      },
      {
        label: "Series C",
        value: "Series C",
      },
      {
        label: "Series D",
        value: "Series D",
      },
      {
        label: "Series E+",
        value: "Series E+",
      },
    ],
  },
  {
    id: 14,
    name: "jobDescription",
    label: "Job description",
    rules: [
      {
        required: true,
        message: "Please enter job description",
      },
    ],
    placeholder: "Enter job description",
    type: "description",
    size: {
      sm: 24,
      md: 24,
    },
  },
];
