import { Col, Row, Typography } from "antd";
import ProfileSubSection from "./ProfileSubSection";

const ProfileSection = ({ fields, heading, isChips }) => {
  return (
    <Row className="profile-section-container" spacing={[12, 0]}>
      <Col md={6}>
        <Typography className="profile-section-heading">{heading}</Typography>
      </Col>
      <Col md={18}>
        {isChips ? (
          <Row gutter={[32, 32]} className="inside-row">
            {/* {isChips?} */}
            {fields.map((fieldData) => (
              <Col md={24}>
                {" "}
                <ProfileSubSection {...fieldData} isChips={isChips} />
              </Col>
            ))}
          </Row>
        ) : (
          <Row gutter={[32, 32]}>
            {/* {isChips?} */}
            {fields.map((fieldData) => (
              <Col md={7}>
                {" "}
                <ProfileSubSection {...fieldData} />
              </Col>
            ))}
          </Row>
        )}
      </Col>
    </Row>
  );
};

export default ProfileSection;
