import { Button, Divider, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import userEdit from "../../Assets/Icons/user-pen-alt.svg";
import Paragraph from "antd/es/typography/Paragraph";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { handleCloseDialog } from "../../Redux/Slices/DialogSlice";
import CustomForm from "../Form";
import { editFieldRenderer, profileSubmitRenderer } from "../../Utils/helpers";
import {
  getAllExperienceLevels,
  getAllJobFunctions,
} from "../../Redux/Slices/DashboardSlice";
import { useNavigate } from "react-router-dom";
import Header from "../DialogHeader";

const EditDialog = ({ loading, status }) => {
  const { open, selectedDialogTab, selectedId, fields } = useSelector(
    (state) => state.DialogSlice
  );
  const [experienceName, setExperienceName] = useState("");
  const [jobFunctionName, setJobFunctionName] = useState("");
  const [experienceLevelValue, setExperienceLevel] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(handleCloseDialog());
    navigate(0);
  };
  const jobFunctionNameHandler = (data) => {
    const temp = [];
    data.map(({ id, name }) => temp.push({ value: id, label: name }));
    setJobFunctionName(temp);
  };
  const experienceNameHandler = (data) => {
    const temp = [];
    data.map(({ id, name }) => temp.push({ value: id, label: name }));
    setExperienceName(temp);
  };
  const FormBtn = ({ label, loading }) => (
    <div className="form-action-container">
      <Button
        htmlType="submit"
        disbaled={loading}
        size="large"
        className="cancel-details-btn"
      >
        Cancel
      </Button>
      <Button
        htmlType="submit"
        disabled={loading}
        type="primary"
        className="save-details-btn"
        size="large"
      >
        {label}
      </Button>
    </div>
  );
  const btnProps = { label: "Save details", loading };
  useEffect(() => {
    dispatch(getAllExperienceLevels({ experienceNameHandler }));
    dispatch(getAllJobFunctions({ jobFunctionNameHandler }));
    setExperienceLevel(fields.experienceLevel);
    // isEditGenerel && jobLocations?.length > 0 && setLocations(jobLocations);
  }, [fields.id]);
  const customConfig = [
    {
      name: "experienceLevel",
      options: experienceName,
    },
    {
      name: "jobFunctionId",
      options: jobFunctionName,
    },
  ];

  const initialValues = {
    jobFunctionId: fields?.jobFunction?.id,
    experienceLevel: fields.experienceLevel,
  };
  console.log("fields", fields?.id === selectedId && Object.keys(fields));
  // useEffect(() => {}, [Object.values(initialValues)[0]]);

  return (
    <Modal
      title={<Header icon={userEdit} heading="Edit Details " />}
      open={open}
      className="profile-modal"
      onCancel={handleCancel}
      width={"866px"}
      footer={null}
      centered
    >
      {fields?.id === selectedId && Object.keys(fields).length > 0 && (
        <CustomForm
          fields={editFieldRenderer(selectedDialogTab)}
          FormBtn={FormBtn}
          btnProps={btnProps}
          customConfig={customConfig}
          initialValues={{ ...initialValues }}
          setExperienceLevel={setExperienceLevel}
          onFinish={profileSubmitRenderer(
            selectedDialogTab,
            selectedId,
            dispatch,
            experienceLevelValue,
            toast,
            status
          )}
        />
      )}
      {/* // ) : (
      //   <Skeleton />
      // )} */}
      <ToastContainer />
    </Modal>
  );
};

export default EditDialog;
